import { createContext, useContext } from 'react';
import { noop } from 'lodash';

export const DEFAULT_CONTEXT_VALUES = {
  licensePlate: '',
  setLicensePlate: noop,
  stateCode: '',
  setStateCode: noop,
  mmy: {},
  setMmy: noop,
  vin: '',
  setVin: noop,
  queryVin: '',
  isVinEdited: false,
  setIsVinEdited: noop,
  styleIds: [],
  setStyleIds: noop,
  isReadyForRedirect: false,
  setIsReadyForRedirect: noop,
  isAppraiserPageOpened: false,
  squishStyles: null,
  setSquishStyles: noop,
  ctaBtnColor: 'success',
  onFindVinModalOpen: noop,
  onFindVinModalClose: noop,
  onMultipleVinsModalOpen: noop,
  onMultipleVinsModalClose: noop,
  isProjectEveStyle: false,
  addressInfo: { firstName: '', lastName: '', address: '', apartment: '', city: '' },
  setAddressInfo: noop,
  wasVinPrePopulated: false,
  setWasVinPrePopulated: noop,
};

export const AppraisalTabsContext = createContext(DEFAULT_CONTEXT_VALUES);

export const useAppraisalTabsContext = () => useContext(AppraisalTabsContext);
