import { get } from 'lodash';
import { isPickupType } from 'site-modules/shared/utils/is-pickup-type';

export function getEngineAttrIcon(vehicleInfo, showElectricityRange) {
  if (showElectricityRange) {
    return 'icon-low-miles';
  }

  const bodyType = get(vehicleInfo, 'styleInfo.bodyType');
  return isPickupType(bodyType) ? 'icon-cog7' : 'icon-tree7';
}
