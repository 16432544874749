import React, { useCallback, useEffect, useState, useRef } from 'react';
import { SemanticSearchModel } from 'client/data/models/semantic-search';
import { get, isEmpty, isNull, isUndefined } from 'lodash';
import { srpLinkBuilderWithParamsConversion } from 'site-modules/shared/utils/srp-link-constructor';
import { transformFacetsToUrlParams } from 'site-modules/shared/utils/inventory/srp-llm-suggested-facets';
import { getVdpLink } from 'site-modules/shared/utils/inventory/usurp-inventory-card';
import { venomHistory } from 'client/utils/history/venom-history';
import { LlmLoadingScreen } from 'site-modules/shared/components/inventory/llm-loading-screen/llm-loading-screen';
import { fireSubmitTracking, fireWidgetViewTracking } from 'site-modules/shared/utils/inventory/global-search-tracking';
import {
  CREATIVE_ID_FILTERS,
  FOUND_VIN_SCREEN_CREATIVE_ID,
} from 'site-modules/shared/constants/global-search/global-search';

export const useLlmResults = ({ suggestedFilters, searchId, setModelValue, query }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStepsFinished, setLoadingStepsFinished] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const searchIdCurrent = useRef(searchId);

  const triggerChange = useCallback(async () => {
    if (isError) {
      await setModelValue('userSearch', SemanticSearchModel, '');
      setIsError(false);
      setLoadingStepsFinished(false);
    }
  }, [setModelValue, isError]);

  const triggerSubmit = useCallback(
    async trimmedQuery => {
      if (trimmedQuery) {
        setIsLoading(true);
        await setModelValue('userSearch', SemanticSearchModel, trimmedQuery);
      }
    },
    [setModelValue]
  );

  const onLoadingCancel = useCallback(() => {
    setIsLoading(false);
    setIsRedirecting(false);
    setLoadingStepsFinished(false);
  }, []);

  const handleLoadingCancel = useCallback(async () => {
    await setModelValue('userSearch', SemanticSearchModel, '');
    onLoadingCancel();
  }, [setModelValue, onLoadingCancel]);

  const onResponseError = useCallback(() => {
    onLoadingCancel();
    setIsError(true);
  }, [onLoadingCancel]);

  const setLoadingStatus = useCallback(() => {
    setLoadingStepsFinished(true);
  }, []);

  const cancelLoadingForCachedPage = useCallback(
    ({ persisted }) => {
      if (persisted) {
        handleLoadingCancel();
      }
    },
    [handleLoadingCancel]
  );

  useEffect(() => {
    window.addEventListener('pageshow', cancelLoadingForCachedPage);

    return () => {
      window.removeEventListener('pageshow', cancelLoadingForCachedPage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading && searchId && searchId !== searchIdCurrent.current) {
      fireSubmitTracking({ input: query, searchId });
      searchIdCurrent.current = searchId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchId]);

  useEffect(() => {
    const filters = get(suggestedFilters, 'filters');
    const modifiedRadius = get(suggestedFilters, 'modifiedRadius');
    const srpUrl = srpLinkBuilderWithParamsConversion(transformFacetsToUrlParams(filters, modifiedRadius));
    const foundVinInfo = get(suggestedFilters, 'identifiedVin.vinInfo');
    const foundVinVdpLink = foundVinInfo ? getVdpLink({ vehicle: foundVinInfo, radius: 0 }) : '';

    if ((!isEmpty(filters) || foundVinInfo) && loadingStepsFinished) {
      setIsRedirecting(true);
      fireWidgetViewTracking({
        value: `srp_search_id=${searchId}`,
        creativeId: foundVinVdpLink ? FOUND_VIN_SCREEN_CREATIVE_ID : CREATIVE_ID_FILTERS,
      });
      venomHistory.push(foundVinVdpLink || srpUrl);
    }
  }, [suggestedFilters, loadingStepsFinished, searchId]);

  useEffect(() => {
    const foundVinInfo = get(suggestedFilters, 'identifiedVin.vinInfo');
    const isNoFacets =
      !foundVinInfo && suggestedFilters && suggestedFilters.filters && !suggestedFilters.filters.length;
    const isResponseError = isNull(suggestedFilters) || isNull(searchId);

    if (isResponseError || isNoFacets) {
      setIsLoading(false);
      onResponseError();
    }
  }, [suggestedFilters, searchId, onResponseError]);

  return {
    triggerSubmit,
    triggerChange,
    isLoading,
    isError,
    loadingComponent: (
      <LlmLoadingScreen
        setLoadingStatus={setLoadingStatus}
        isSubmittingFinished={!isUndefined(get(suggestedFilters, 'filters'))}
        onCancel={handleLoadingCancel}
        isCancelDisabled={!!isRedirecting}
        isLlmDrawerOpen
      />
    ),
  };
};
