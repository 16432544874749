import React, { useCallback, useDeferredValue, useState } from 'react';
import PropTypes from 'prop-types';
import { flow } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';
import { ApiMetrics, withMetrics } from 'client/utils/metrics-hoc';
import { InventoryEntities } from 'client/data/models/inventory';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { useLlmResults } from 'site-modules/shared/hooks/global-search/use-llm-results';

function GlobalSearchQueryUI({
  isMobile,
  setModelValue,
  suggestedFilters,
  searchId,
  apiMetrics,
  withLlmSearchDisabled,
  isAds9843Enabled,
}) {
  const [query, setQuery] = useState('');
  const deferredQuery = useDeferredValue(query);
  const trimmedQuery = deferredQuery?.trim();

  const { triggerSubmit, triggerChange, isLoading, isError, loadingComponent } = useLlmResults({
    suggestedFilters,
    searchId,
    setModelValue,
    query: trimmedQuery,
  });

  const onSearchChange = useCallback(
    async value => {
      setQuery(value);

      await triggerChange();
    },
    [triggerChange]
  );

  return (
    <GlobalSearch
      query={query}
      onSearchSubmit={triggerSubmit}
      onSearchChange={onSearchChange}
      loadingComponent={loadingComponent}
      apiMetrics={apiMetrics}
      isMobile={isMobile}
      isLoading={isLoading}
      isError={isError}
      withLlmSearchDisabled={withLlmSearchDisabled}
      isAds9843Enabled={isAds9843Enabled}
    />
  );
}

GlobalSearchQueryUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  apiMetrics: ApiMetrics.isRequired,
  searchId: PropTypes.string,
  suggestedFilters: PropTypes.shape({
    filters: PropTypes.arrayOf(InventoryEntities.FacetValue),
    inventoryCount: PropTypes.number,
    modifiedRadius: PropTypes.number,
    identifiedVin: PropTypes.shape({
      vin: PropTypes.string,
      vinInfo: InventoryEntities.InventoryVin,
      styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
          makeNiceId: PropTypes.string,
          modelNiceId: PropTypes.string,
          year: PropTypes.number,
          styleId: PropTypes.number,
        })
      ),
    }),
  }),
  isMobile: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
  isAds9843Enabled: PropTypes.bool,
};

GlobalSearchQueryUI.defaultProps = {
  searchId: '',
  suggestedFilters: {},
  isMobile: false,
  withLlmSearchDisabled: false,
  isAds9843Enabled: false,
};

export const GlobalSearchQuery = flow(
  withMetrics,
  component =>
    connectToModel(component, {
      suggestedFilters: bindToPath(
        ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
        SemanticSearchModel,
        transformSuggestedFilters,
        false
      ),
    }),
  component =>
    connectToModel(component, {
      searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
    })
)(GlobalSearchQueryUI);
