export const VEHICLES_NUM_TO_RANK = 3;
export const ELECTRIC_SUBSEGMENT = 'Electric';

export const TYPE_JELLYBEANS = {
  suv: {
    url: '/suv/',
    image: '/unit/find-type-center/suv-174x74.png',
    name: 'SUV',
    alt: 'go to SUV type page',
  },
  sedan: {
    url: '/sedan/',
    image: '/unit/find-type-center/sedan-174x74.png',
    name: 'Sedan',
    alt: 'go to Sedan type page',
  },
  coupe: {
    url: '/coupe/',
    image: '/unit/find-type-center/coupe-174x74.png',
    name: 'Coupe',
    alt: 'go to Coupe type page',
  },
  hatchback: {
    url: '/hatchback/',
    image: '/unit/find-type-center/hatchback-174x74.png',
    name: 'Hatchback',
    alt: 'go to Hatchback type page',
  },
  truck: {
    url: '/truck/',
    image: '/unit/find-type-center/truck-174x74.png',
    name: 'Truck',
    alt: 'go to Truck type page',
  },
  wagon: {
    url: '/wagon/',
    image: '/unit/find-type-center/wagon-174x74.png',
    name: 'Wagon',
    alt: 'go to Wagon type page',
  },
  convertible: {
    url: '/convertible/',
    image: '/unit/find-type-center/convertible-174x74.png',
    name: 'Convertible',
    alt: 'go to Convertible type page',
  },
  minivan: {
    url: '/minivan/',
    image: '/unit/find-type-center/minivan-174x74.png',
    name: 'Minivan',
    alt: 'go to Minivan type page',
  },
  electric: {
    url: '/electric-car',
    image: '/unit/find-type-center/electric-car-v2-174x74.png',
    name: 'Electric',
    alt: 'go to Electric type page',
  },
  hybrid: {
    url: '/hybrid/',
    image: '/unit/find-type-center/hybrid-174x74.png',
    name: 'Hybrid',
    alt: 'go to Hybrid type page',
  },
};

export const REPLACE_WITH_IMAGE = {
  'chevrolet/bolt-ev/2023':
    'https://media.ed.edmunds-media.com/chevrolet/bolt-ev/2023/oem/2023_chevrolet_bolt-ev_4dr-hatchback_2lt_fq_oem_1_815.jpg',
  'nissan/leaf/2024':
    'https://media.ed.edmunds-media.com/nissan/leaf/2023/oem/2023_nissan_leaf_4dr-hatchback_sv-plus_fq_oem_1_600.jpg',
  'mini/hardtop-2-door/2023':
    'https://media.ed.edmunds-media.com/mini/hardtop-2-door/2023/oem/2023_mini_hardtop-2-door_2dr-hatchback_cooper-se_fq_oem_1_815.jpg',
  'volkswagen/taos/2023':
    'https://media.ed.edmunds-media.com/volkswagen/taos/2022/oem/2022_volkswagen_taos_4dr-suv_se_fq_oem_1_815.jpg',
  'chevrolet/trailblazer/2024':
    'https://media.ed.edmunds-media.com/chevrolet/trailblazer/2024/oem/2024_chevrolet_trailblazer_4dr-suv_activ_fq_oem_1_815.jpg',
  'mazda/cx-30/2024':
    'https://media.ed.edmunds-media.com/mazda/cx-30/2024/oem/2024_mazda_cx-30_4dr-suv_25-s-carbon-edition_fq_oem_1_815.jpg',
};
export const REPLACE_WITH_FETCHPRIORITY = [
  'toyota/prius/2024',
  'hyundai/sonata-hybrid/2023',
  'toyota/crown/2023',
  'volkswagen/taos/2023',
  'chevrolet/trailblazer/2024',
  'mazda/cx-30/2024',
];
export const REPLACE_TYPES = ['electric', 'suv', 'hybrid'];
