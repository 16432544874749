import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { round } from 'lodash';
import './rating-stars.scss';

const starsQuantity = 5;

function starsConstructor(rating) {
  const stars = [];
  for (let i = 0; i < starsQuantity; i += 1) {
    const diff = rating - i;
    if (diff >= 1) {
      stars.push('icon-star-full');
    } else if (diff > 0 && diff < 1) {
      stars.push('icon-star-empty2');
    } else {
      stars.push('icon-star-empty');
    }
  }
  return stars;
}

export function RatingStars({ rating, className, starClass, hideForScreenReader }) {
  const readableRating = round(rating, 2);
  return (
    <span className={classNames('rating-stars', className)}>
      {/*
          Sometimes these star ratings duplicate the actual numerical rating that is displayed right next to them.
          In those cases, we do not want to add the screen-reader-only text to the DOM because immediately reading
          duplicate information is unpleasant for screen-reader users.

          However, if you are using hideForScreenReader, then please make sure you add a `sr-only` element next
          to your numerical rating.  Just putting "4" in the markup is not helpful.  While a sighted user might be
          able to make sense of it, a screen reader may not be able to.

          So, when you are using hideForScreenReader, strongly consider making your parent or sibling element look like:

          4 <span className="sr-only">out of 5 stars</span>
         */
      !hideForScreenReader && <span className="sr-only">{`${readableRating} out of ${starsQuantity} stars`}</span>}
      {starsConstructor(readableRating).map((icon, index) => (
        <span
          key={index} // eslint-disable-line react/no-array-index-key
          className={classNames('rating-star', icon, starClass)}
          aria-hidden
        />
      ))}
    </span>
  );
}

RatingStars.propTypes = {
  /* see comments in the markup before using hideForScreenReader */
  hideForScreenReader: PropTypes.bool,
  rating: PropTypes.number,
  className: PropTypes.string,
  starClass: PropTypes.string,
};

RatingStars.defaultProps = {
  hideForScreenReader: false,
  rating: 0,
  className: '',
  starClass: '',
};
