import React from 'react';
import PropTypes from 'prop-types';

/**
 * Preventing Icon Wrap After Text, can be extended to "Before Text"
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */

const NBSP = String.fromCodePoint(0x000a0);
export function StickyIcon({ className, style, after, before }) {
  return (
    <span className="text-nowrap">
      {after && NBSP}
      <i className={className} style={style} aria-hidden />
      {before && NBSP}
    </span>
  );
}
StickyIcon.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  after: PropTypes.bool,
  before: PropTypes.bool,
};

StickyIcon.defaultProps = {
  style: null,
  after: true,
  before: false,
};
