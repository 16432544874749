import gql from 'graphql-tag';
import { get } from 'lodash';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';

const DEFAULT = 'default';

export function buildSegmentRatingsPath({ makeSlug, modelSlug, year }) {
  return `makes["${makeSlug}"].models["${modelSlug}"].years["${year}"].segmentRatings`;
}

export function buildBadgesPath({ makeSlug, modelSlug, year, submodelSlug = DEFAULT }) {
  return `makes["${makeSlug}"].models["${modelSlug}"].years["${year}"].submodels["${submodelSlug}"].badges`;
}

export const TypeRankingsFeaturesModel = createModelSegment('typeRankingsFeatures', [
  /**
   * @see buildSegmentRatingsPath
   * @returns {Array<SegmentRating>}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].segmentRatings',
    resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($makeSlug: String!, $modelSlug: String!, $year: Int!) {
              modelYears(makeSlug: $makeSlug, modelSlug: $modelSlug, year: $year) {
                segmentRatings {
                  baseStyleId
                  badges
                  rank
                  slugRankedSubmodel
                  editorialSegment {
                    edmundsTypeCategory
                    id
                    displayName
                    segmentRatings(filter: { ratingMode: NEW }, pagesize: 100, rankRange: { start: 1, end: 100 }) {
                      baseStyleId
                      modelYear {
                        makeName
                        makeSlug
                        modelName
                        modelSlug
                        year
                      }
                      rank
                      rating
                    }
                  }
                }
              }
            }
          `,
          {
            makeSlug,
            modelSlug,
            year: parseInt(year, 10),
          }
        )
        .then(response => get(response, 'modelYears[0].segmentRatings', null));
    },
  },
  /**
   * @see buildBadgesPath
   * @returns {Badges}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].submodels["{submodelSlug}"].badges',
    resolve({ makeSlug, modelSlug, year, submodelSlug }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($makeSlug: String!, $modelSlug: String!, $year: Int!) {
              modelYears(makeSlug: $makeSlug, modelSlug: $modelSlug, year: $year) {
                segmentRatings {
                  badges
                  slugRankedSubmodel
                }
              }
            }
          `,
          {
            makeSlug,
            modelSlug,
            year: parseInt(year, 10),
          }
        )
        .then(response => {
          const segmentRatings = get(response, 'modelYears[0].segmentRatings', []);
          const currentSegment =
            segmentRatings.find(({ slugRankedSubmodel }) => slugRankedSubmodel === submodelSlug) ||
            segmentRatings.find(({ slugRankedSubmodel }) => !slugRankedSubmodel);

          return get(currentSegment, 'badges', null);
        });
    },
  },
]);
