import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { pickBy } from 'lodash';
import classnames from 'classnames';
import { FocusScope } from '@react-aria/focus';
import Fade from 'reactstrap/lib/Fade';
import { getOriginalBodyPadding, conditionallyUpdateScrollbar, setScrollbarWidth } from 'reactstrap/lib/utils';
import { EventToolbox } from 'client/utils/event-toolbox';
import { scrollToPos } from 'client/utils/scroll';
import { RestoreFocus } from 'site-modules/shared/components/restore-focus/restore-focus';
import { DRAWER_EVENTS } from './events';

import './drawer.scss';

const SIDES = ['left', 'right', 'top', 'bottom'];
const SIZES = ['xsmall', 'small', 'medium', 'full'];
const DISPLAY = {
  OPEN: 'OPEN',
  OPENING: 'OPENING',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
};
const DRAWERS_CONTAINER_SELECTOR = 'drawers-container';
const PAGE_SELECTOR = 'edm-page';

const propTypes = {
  isOpen: PropTypes.bool, // defines whether drawer is open or not, false by default
  side: PropTypes.oneOf(SIDES), // which side drawer should be open (left, right, top, bottom), left by default
  size: PropTypes.oneOf(SIZES), // drawer size (xsmall, small, medium or full), medium by default
  toggle: PropTypes.func.isRequired, // drawer toggle handler/callback, should toggle isOpen property
  backdrop: PropTypes.oneOfType([
    // defines whether backdrop is displayed or not, true by default
    PropTypes.bool, //
    PropTypes.oneOf(['static']), // if it's 'static' then it's displayed, but drawer is not closed on click
  ]),
  keyboard: PropTypes.bool, // defines whether drawer listens to keyboard events,
  // if true drawer is closed on ESC press, true by default
  onShow: PropTypes.func, // callback function called when drawer is shown
  onHide: PropTypes.func, // callback function called when drawer is hidden
  classNames: PropTypes.shape({
    // drawer custom class names
    drawer: PropTypes.string, // root level class name
    drawerContainer: PropTypes.string, // drawer inner container class name
    drawerContent: PropTypes.string, // drawer content container class name
    backdrop: PropTypes.string, // drawer backdrop class name
  }),
  children: PropTypes.node, // drawer children nodes
  ssrMode: PropTypes.bool,
  ariaLabel: PropTypes.string,
  trapFocus: PropTypes.bool,
  id: PropTypes.string,
  noInitialFocus: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  side: 'left',
  size: 'medium',
  backdrop: true,
  keyboard: true,
  onShow: () => {},
  onHide: () => {},
  classNames: {},
  children: null,
  ssrMode: false,
  ariaLabel: null,
  trapFocus: true,
  id: undefined,
  noInitialFocus: false,
};

export const OPEN_ANIMATION = 500;
export const FADE_ANIMATION = 300;

/**
 * Drawer component (https://edmunds.atlassian.net/browse/PLAT-85)
 * Open state is controlled by the parent component and provided via `isOpen` property.
 * Drawer can be displayed on left, top, right, bottom sides and have medium (11 columns) or full sizes (e.g. width).
 * `toggle` callback function is required as expected to toggle component `isOpen` property.
 *
 * Example:
 *
 *    <Drawer
 *      isOpen={this.state.isOpen}
 *      side="left"
 *      size="medium"
 *      toggle={() => { this.setState({ isOpen: !this.state.isOpen }) }}
 *      classNames={{
 *        drawer: 'menu-drawer',
 *        drawerContainer: 'bg-primary-darker text-white'
 *      }}
 *    >
 *      <div>
 *        <div className="menu-header align-right">
 *          <button onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>X</button>
 *        </div>
 *        <div className="menu-list">
 *          <ul>
 *            <li><a href="/">home</a></li>
 *            <li><a href="/new">new</a></li>
 *            <li><a href="/used">used</a></li>
 *            <li><a href="/calculators">calculators</a></li>
 *          </ul>
 *        </div>
 *      </div>
 *    </Drawer>
 */
export class Drawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.isOpen ? DISPLAY.OPEN : DISPLAY.CLOSED, // display state is used for animation
      isOpen: props.isOpen,
    };

    this.originalBodyPadding = null;
    this.drawersContainer = null;
  }

  /**
   * Create container for all drawers.
   * Toggles drawer after component mount event.
   *
   * @return {void}
   */
  componentDidMount() {
    this.createContainer();
    if (this.props.isOpen) this.toggleDrawer();
  }

  /**
   * getDerivedStateFromProps handler
   * sets proper display animation property
   *
   * @param  {Object} props New props values
   * @param  {Object} state New state values
   * @return {Object} new state or null
   */
  static getDerivedStateFromProps(props, state) {
    if (state.isOpen !== props.isOpen) {
      const currDisplay = state.display;
      if (currDisplay === DISPLAY.CLOSED && props.isOpen) {
        return {
          display: DISPLAY.OPENING,
          isOpen: props.isOpen,
        };
      } else if (currDisplay === DISPLAY.OPEN && !props.isOpen) {
        return {
          isOpen: props.isOpen,
          display: DISPLAY.CLOSING,
        };
      }
    }
    return null;
  }

  /**
   * Toggles drawer is isOpen state is updated
   *
   * @param  {Object} prevProps Previous props
   * @param  {Object} prevState Previous state
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      // handle drawer events/dom updates
      this.toggleDrawer();
    }

    if (this.state.display === DISPLAY.OPEN && this.state.display !== prevState.display && !this.props.noInitialFocus) {
      this.focusDialog();
    }
  }

  /**
   * Destroys the drawer if it's open.
   */
  componentWillUnmount() {
    if (this.props.isOpen) {
      this.destroy();
    }
  }

  /**
   * Calls focus on the dialog if it exists
   */
  focusDialog() {
    if (this.dialog) {
      this.dialog.focus();
    }
  }

  /**
   * Handles on ESC button press event
   * Calls provided toggle callback function which is expected toggling isOpen property
   *
   * @param  {Object} e Button press event
   * @return {void}
   */
  handleEscape = e => {
    if (this.props.keyboard && e.keyCode === 27) {
      this.props.toggle();
    }
  };

  /**
   * Handles backdrop area click event
   * Calls provided toggle callback function which is expected toggling isOpen property
   *
   * @param  {Object} e Click event
   * @return {void}
   */
  handleBackdropClick = e => {
    if (this.props.backdrop !== true) return;

    if (e.target && this.container && !this.container.contains(e.target)) {
      this.props.toggle();
    }
  };

  /**
   * Checks if the drawers container exists, and if not, creates it.
   *
   * @return {void}
   */
  createContainer = () => {
    const drawersContainer = document.querySelector(`.${DRAWERS_CONTAINER_SELECTOR}`);

    if (drawersContainer) {
      this.drawersContainer = drawersContainer;
    } else {
      const page = document.querySelector(`.${PAGE_SELECTOR}`);

      this.drawersContainer = document.createElement('div');
      this.drawersContainer.classList.add(DRAWERS_CONTAINER_SELECTOR);
      page.appendChild(this.drawersContainer);
    }
  };

  /**
   * Toggles drawer depending on isOpen property
   * e.g. adds/removes required classes to/from the document body
   * and adds/removes backdrop to/from the document DOM
   *
   * @return {[type]} [description]
   */
  toggleDrawer = () => {
    if (this.props.isOpen) {
      this.show();
    } else {
      this.hide();
    }
  };

  /**
   * Removes drawer specific classes from the document body
   * Removes backdrop container from DOM
   * restores scrollbar
   *
   * @return {void}
   */
  destroy = () => {
    document.body.classList.remove('modal-open', 'drawer-open', `drawer-${this.props.side}`);
    document.documentElement.classList.remove('modal-open');
    setScrollbarWidth(this.originalBodyPadding);

    // Restore scroll position after closing the drawer
    const offsetY = Math.abs(parseInt(document.body.style.top || 0, 10));
    if (offsetY) {
      document.body.style.removeProperty('top');
      scrollToPos(0, offsetY);
    }
  };

  /**
   * Animates drawer and backdrop on drawer close
   * Removes drawer from DOM
   *
   * @return {void}
   */
  hide = () => {
    // let drawer content animation finish first, 300ms is animation duration
    setTimeout(() => {
      this.setState({
        display: DISPLAY.CLOSED,
      });
      this.props.onHide();
    }, FADE_ANIMATION);
    this.destroy();
    EventToolbox.fireCustomEvent(DRAWER_EVENTS.CLOSED, {}, this.dialog);
  };

  /**
   * Adds required classes to the document body
   * Adds backdrop to DOM
   * Animates drawer and backdrop on drawer open
   *
   * @return {void}
   */
  show = () => {
    const classes = document.body.className;
    this.originalBodyPadding = getOriginalBodyPadding();
    conditionallyUpdateScrollbar();
    const currentScrollPosition = window.scrollY;

    document.body.className = classnames(classes, 'modal-open drawer-open', `drawer-${this.props.side}`);

    // Restore scroll position. When body has "position: fixed" browsers scroll page to the top.
    // "position: fixed" on body element is needed to prevent page scrolling behind the drawer in Safari on iOS.
    // See body styles details in drawer.scss
    document.body.style.top = `${-currentScrollPosition}px`;

    const htmlClasses = document.documentElement.className;
    document.documentElement.className = classnames(htmlClasses, 'modal-open');

    this.setState({
      display: DISPLAY.OPEN,
    });
    this.props.onShow();
    EventToolbox.fireCustomEvent(DRAWER_EVENTS.OPEN, {}, this.dialog);
  };

  /**
   * Renders backdrop markup if drawer is open
   *
   * @return {ReactElement} Rendered backdrop markup
   */
  renderBackdrop = () => {
    const { isOpen } = this.props;

    return (
      <Fade
        in={isOpen}
        key="drawer-backdrop"
        className={classnames('modal-backdrop', this.props.classNames.backdrop, {
          'd-none': this.state.display === DISPLAY.CLOSED,
        })}
      />
    );
  };

  /**
   * Renders Drawer content
   * @return {ReactElement} Rendered drawer content markup
   */
  renderDrawerContent() {
    const args = pickBy(this.props, (value, key) => key.startsWith('data-') || key.startsWith('aria-'));

    const { side, size, children, classNames, ariaLabel, trapFocus, id } = this.props;
    const { display } = this.state;

    const isInnerScopeActive = display !== DISPLAY.CLOSED;

    return (
      <RestoreFocus isInnerScopeActive={isInnerScopeActive}>
        <FocusScope contain={trapFocus && isInnerScopeActive}>
          <div
            {...args}
            id={id}
            key="drawer-dialog"
            onClickCapture={this.handleBackdropClick}
            onKeyUp={this.handleEscape}
            className={classnames(
              `modal-drawer drawer-${side} fade`,
              {
                [`dr-${size}`]: size,
                show: display === DISPLAY.OPEN,
              },
              classNames.drawer
            )}
            style={{ display: display !== DISPLAY.CLOSED ? 'block' : 'none' }}
            tabIndex={-1}
            role="dialog"
            aria-hidden={display !== DISPLAY.OPEN}
            ref={d => {
              this.dialog = d;
            }}
            aria-label={ariaLabel}
          >
            <div
              className={classnames('drawer-container modal-dialog', classNames.drawerContainer)}
              role="document"
              ref={c => {
                this.container = c;
              }}
            >
              <div
                className={classnames('drawer-content modal-content', classNames.drawerContent)}
                ref={c => {
                  this.content = c;
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </FocusScope>
      </RestoreFocus>
    );
  }

  /**
   * Places Drawer Content into portal if applicable
   * @return {ReactElement} Rendered drawer content markup
   */
  render() {
    const { ssrMode, backdrop } = this.props;
    const noRender = !ssrMode && !this.drawersContainer;
    const serverSideRender = ssrMode && !this.drawersContainer;

    if (noRender) return null;

    const content = this.renderDrawerContent();
    return serverSideRender ? (
      content
    ) : (
      <>
        {backdrop && createPortal(this.renderBackdrop(), this.drawersContainer)}
        {createPortal(content, this.drawersContainer)}
      </>
    );
  }
}

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;
