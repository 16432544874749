import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const map = {
  suggested_vehicle_make_select: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MAKE_SELECT
  ),

  suggested_vehicle_model_select: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MODEL_SELECT
  ),

  suggested_vehicle_zip_code_entry: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMZ_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),
};

export const SuggestedVehiclePricingCarouselMap = {
  ...map,
};
