import { createModelSegment } from 'client/data/luckdragon/segment';
import { get, isEmpty, uniqBy } from 'lodash';
import { InventoryAggregateModel, buildMMYSubmodelTypeAmountsPath } from 'client/data/models/inventory-aggregate';
import { logger } from 'client/utils/isomorphic-logger';
import { VehicleModel, buildSubmodelsPath, buildMmysMsrpPricesPath } from 'client/data/models/vehicle';
import { buildReviewPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import { VisitorHistoryModel } from './visitor-history';

const ERROR_MESSAGES = {
  pricingError: 'Error while fetching Return Dashboard item price ranges:',
  photoError: 'Error while fetching Return Dashboard item photo data:',
};

export function getMakeModeldataPath(makeModelItem) {
  const { makeNiceId = null, modelNiceId = null, year = null, useIn = null } = makeModelItem;
  return `makes["${makeNiceId}"].models["${modelNiceId}"].years["${year}"].inventoryType["${useIn}"]`;
}

/**
 * Creates list of promises used to pull make model data.
 * @param {Array} makeModels List of make model values returned by UDM
 * @return {Array} Make Models list with data paths
 */
export function createMakeModelDataPaths(makeModels) {
  if (isEmpty(makeModels)) return [];
  const makeModelDataPaths = makeModels.map(makeModelItem => getMakeModeldataPath(makeModelItem));
  return makeModelDataPaths;
}

export const ReturnDashboardModel = createModelSegment('returnDashboard', [
  /**
   * Used for recently viewed vehicles
   * Performs data retrieval for MakeModel items
   * Builds price range data for Make-Models with Year
   * @see getMakeModeldataPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].inventoryType["{useIn}"]',
    async resolve(match, context) {
      const { make, model, useIn, year } = match;
      if (year === 'null') {
        return {};
      }
      // Return submodel pricing

      const vehicleParams = {
        make,
        model,
        year,
        isUsed: useIn === 'used',
      };
      const submodelsPath = buildSubmodelsPath(vehicleParams);
      const submodelList = await context.resolveValue(submodelsPath, VehicleModel);
      const submodelSlug = get(submodelList, '[0].slug', null);

      let pricingData = {};

      if (useIn === 'used') {
        const submodelPricingPath = buildMMYSubmodelTypeAmountsPath(vehicleParams);
        const prices = await context.resolveValue(submodelPricingPath, InventoryAggregateModel).catch(error => {
          logger('warn', `${ERROR_MESSAGES.pricingError} ${error}`);
          return {};
        });
        pricingData = get(prices, `priceRanges["${submodelSlug}"]`, {});
      } else {
        // Retrieve MSRP for both new and preprod
        const msrpPricePath = buildMmysMsrpPricesPath({ ...vehicleParams, submodel: submodelSlug });
        const msrpPricing = await context.resolveValue(msrpPricePath, VehicleModel).catch(error => {
          logger('warn', `${ERROR_MESSAGES.pricingError} ${error}`);
          return {};
        });
        pricingData = !isEmpty(msrpPricing)
          ? {
              min: msrpPricing[msrpPricing.length - 1],
              max: msrpPricing[0],
            }
          : {};
      }

      if (useIn === 'preprod') {
        const preprodData = await context.resolveValue(
          buildReviewPath({ ...vehicleParams, submodel: submodelSlug }),
          EditorialReviewModel
        );
        const preprodPricing = get(preprodData, 'firstContent.price', null);
        pricingData.preprodPricing = preprodPricing ? preprodPricing.value.replace('up to', '-') : null;
      }

      return { pricingData };
    },
  },
  {
    path: 'aggregatedMakeModels',
    async resolve(_, context) {
      const MAX_SIZE = 4;
      return context
        .resolveValue('aggregatedHistory.withoutRetries', VisitorHistoryModel)
        .then(userHistory =>
          uniqBy(
            get(userHistory, 'modelAggregates', []).filter(({ year }) => !!year),
            item => item.year + item.make + item.model
          ).slice(0, MAX_SIZE)
        );
    },
  },
]);
