import gql from 'graphql-tag';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { GRAPHQL_FRAGMENTS } from 'client/data/constants/graphql-fragments';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';

export function buildBestVehiclesByTypePath(type) {
  return `bestVehicles["${type}"]`;
}

export const HomePageModel = createModelSegment('homepage', [
  /**
   * @see buildBestVehiclesByTypePath
   */
  {
    path: 'bestVehicles["suv"]',
    resolve(match, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            ${GRAPHQL_FRAGMENTS.suvSegmentFields}
            query {
              smallSUVs: vehicleEditorialRankings(edmundsTypeCategory: "SUV", market: "SUVs", subsegment: "Small") {
                ...suvSegmentFields
              }
              midsizeSUVs: vehicleEditorialRankings(edmundsTypeCategory: "SUV", market: "SUVs", subsegment: "Midsize") {
                ...suvSegmentFields
              }
              largeSUVs: vehicleEditorialRankings(edmundsTypeCategory: "SUV", market: "SUVs", subsegment: "Large") {
                ...suvSegmentFields
              }
              smallLuxurySUVs: vehicleEditorialRankings(
                edmundsTypeCategory: "SUV"
                market: "Luxury SUVs"
                subsegment: "Small"
              ) {
                ...suvSegmentFields
              }
              midsizeLuxurySUVs: vehicleEditorialRankings(
                edmundsTypeCategory: "SUV"
                market: "Luxury SUVs"
                subsegment: "Midsize"
              ) {
                ...suvSegmentFields
              }
              largeLuxurySUVs: vehicleEditorialRankings(
                edmundsTypeCategory: "SUV"
                market: "Luxury SUVs"
                subsegment: "Large"
              ) {
                ...suvSegmentFields
              }
            }
          `
        )
        .then(categories => Object.values(categories).map(category => category[0]));
    },
  },
]);
