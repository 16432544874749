import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { BADGE } from 'client/data/models/type-rankings-entities';
import { buildBadgesPath, TypeRankingsFeaturesModel } from 'client/data/models/type-rankings-features';
import { getStaticImageUrl } from 'client/utils/image-helpers';

export const TOP_RATED_BADGE_PATH = '/img/top-rated/2023/badge.svg';

export function TopRatedBadgeUI({ height, className, isTopRated }) {
  if (!isTopRated) {
    return null;
  }

  return (
    <img
      height={height}
      className={classnames('top-rated-badge', className)}
      src={getStaticImageUrl(TOP_RATED_BADGE_PATH)}
      alt="Top Rated vehicle"
      loading="lazy"
    />
  );
}

TopRatedBadgeUI.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
  isTopRated: PropTypes.bool,
};

TopRatedBadgeUI.defaultProps = {
  height: 17,
  className: null,
  isTopRated: false,
};

const stateToPropsConfig = {
  isTopRated: bindToPath(({ vehicleParams }) => buildBadgesPath(vehicleParams), TypeRankingsFeaturesModel, badges =>
    badges.some(badge => badge === BADGE.TOP_RATED)
  ),
};

export const TopRatedBadge = connectToModel(TopRatedBadgeUI, stateToPropsConfig);

TopRatedBadge.propTypes = {
  vehicleParams: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    submodelSlug: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
};

TopRatedBadge.defaultProps = {
  className: null,
};
