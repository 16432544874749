import { compact, get } from 'lodash';
import { getBedLengthValue } from 'site-modules/shared/utils/features-format';
import { isPickupType } from 'site-modules/shared/utils/is-pickup-type';

export function getEngineAttr(vehicleInfo, showElectricityRange) {
  const electricityRange = get(vehicleInfo, 'partsInfo.electricityRange', '');

  if (showElectricityRange) {
    return electricityRange ? `${electricityRange}mi EPA electric range` : '';
  }

  const bodyType = get(vehicleInfo, 'styleInfo.bodyType');
  const isPickup = isPickupType(bodyType);
  const engineSize = get(vehicleInfo, 'partsInfo.engineSize');
  const cylinders = get(vehicleInfo, 'partsInfo.cylinders');
  const transmission = get(vehicleInfo, 'partsInfo.transmission');
  const bedLength = getBedLengthValue(get(vehicleInfo, 'partsInfo.bedLengthMeasurement'));

  return compact([
    isPickup && bedLength && `${bedLength} Bed`,
    isPickup && engineSize && `${engineSize}L`,
    cylinders && `${cylinders}cyl`,
    !isPickup && transmission,
  ]).join(isPickup && bedLength ? ', ' : ' ');
}
