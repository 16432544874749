import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { TrackingHelper } from 'client/tracking/maps/helper';

const map = {
  select_dealer: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LEAD_SUBMISSION,
    TrackingConstant.ACTION_SELECT_DEALERSHIP
  ),
  unselect_dealer: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LEAD_SUBMISSION,
    TrackingConstant.ACTION_UNSELECT_DEALERSHIP
  ),
  ulf_call_dealer(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_DEALER_ENGAGEMENT,
      TrackingConstant.CALL_DEALER,
      TrackingHelper.getBasicDealerOptions(options)
    );
  },
  expand_lead_form: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LEAD_SUBMISSION,
    TrackingConstant.EXPAND_LEAD_FORM
  ),
  reviews_specs: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.REVIEW_SPECS),

  lead_form_view_vin_details(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_VIEW_VIN_DETAILS,
      TrackingConstant.ACTION_VIEW_VIN_DETAILS,
      TrackingHelper.getBasicDealerOptions(options)
    );
  },

  lead_form_color_view_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT
  ),
  thank_you_srp_button: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'thank_you_srp_button'),
  thank_you_srp_button_docked: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'thank_you_srp_button_docked'
  ),
  lead_form_comments_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_LEAD_SUBMISSION,
    TrackingConstant.EXPOSE_COMMENTS
  ),
  lead_form_comments_collapse: {},
  lead_form_subject_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_LEAD_SUBMISSION,
    TrackingConstant.SUBJECT_SELECT
  ),
  next_user_info_click: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_PRICING_DETAILS,
    TrackingConstant.NEXT_BUTTON
  ),
  lead_form_widget_view: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WIDGET_VIEW,
    TrackingConstant.ACTION_WIDGET_VIEW
  ),
  lead_form_go_to_price_checker: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.GO_TO_PRICE_CHECKER
  ),
  lead_form_go_to_msrp: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.GO_TO_MSRP),
  show_days_to_sell_urgent(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_SHOW_CONTENT,
      TrackingConstant.SHOW_DAYS_TO_SELL_URGENT,
      options
    );
  },
  show_days_to_sell(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_SHOW_CONTENT,
      TrackingConstant.SHOW_DAYS_TO_SELL,
      options
    );
  },
  final_offer_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    TrackingConstant.OFFER_BUTTON
  ),
  view_invoice_description_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'view_invoice_price_full_description'
  ),
  pc_video_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'video_link'),
};

export const LeadFormMap = {
  ...map,
};

export const TRACKING_CREATIVE_ID_SUFFIX = {
  leadForm: '_lead_form',
  thankYou: '_thank_you',
};
