import React, { Fragment } from 'react';
import { Link } from 'site-modules/shared/components/link/link';

import iconNewCars from 'client/images/ads/ads-10008-icon-new-cars.svg';
import iconCalculator from 'client/images/ads/ads-10008-icon-calculator.svg';
import iconAppraisal from 'client/images/ads/ads-10008-icon-appraisal.svg';
import iconEditorial from 'client/images/ads/ads-10008-icon-editorial.svg';
import iconLocator from 'client/images/ads/ads-10008-icon-locator.svg';

export const BRAND_PAGE_FILTER = {
  ALL: 'all',
  CAR_REVIEWS: 'car-reviews',
};

export const BRAND_ALIGNMENT_ITEMS = (pageFilter = BRAND_PAGE_FILTER.ALL) =>
  [
    {
      label: 'Know before you buy',
      description: (
        <Fragment>
          Honest{' '}
          <Link
            to="/car-reviews/"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="view_content"
            aria-label="Expert Car Reviews"
          >
            reviews
          </Link>
          , rankings and video test-drives
        </Fragment>
      ),
      icon: 'icon-star-empty',
      filter: [BRAND_PAGE_FILTER.ALL],
    },
    {
      label: 'Know before you buy',
      description: (
        <Fragment>
          Honest{' '}
          <Link
            to="/car-reviews/car-reviews-road-tests.html"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="view_content"
            aria-label="Expert Car Reviews"
          >
            reviews
          </Link>
          , rankings and video test-drives
        </Fragment>
      ),
      icon: 'icon-star-empty',
      filter: [BRAND_PAGE_FILTER.CAR_REVIEWS],
    },
    {
      label: 'Know your offer',
      description: (
        <Fragment>
          Deal ratings on{' '}
          <Link
            to="/new-cars/"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="view_content"
            aria-label="New Car Pricing"
          >
            new
          </Link>{' '}
          and{' '}
          <Link
            to="/used-cars-for-sale/"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="view_content"
            aria-label="Used Car Listing"
          >
            used
          </Link>{' '}
          listings near you
        </Fragment>
      ),
      icon: 'icon-good-deal',
    },
    {
      label: 'Know your financing',
      description: (
        <Fragment>
          Prequalify for financing and{' '}
          <Link
            to="/financing/prequalify-and-shop/intro/"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="open_financing_app"
            data-tracking-value="financing"
            aria-label="Capital One Application"
          >
            know your real payment
          </Link>
        </Fragment>
      ),
      icon: 'icon-calculator',
    },
    {
      label: 'Know your trade-in value',
      description: (
        <Fragment>
          Instant{' '}
          <Link
            to="/appraisal/"
            rel="nofollow"
            className="text-primary-darker text-underline"
            data-tracking-id="view_content"
            aria-label="Car Appraisal Tool"
          >
            online appraisal
          </Link>{' '}
          — fast and free
        </Fragment>
      ),
      icon: 'icon-appraisal',
    },
  ].filter(({ filter }) => !filter || filter.includes(pageFilter));

export const SMART_SHOPPING_ITEMS = (pageFilter = BRAND_PAGE_FILTER.ALL) =>
  [
    {
      label: 'Know your<br/>trade-in value',
      description: <Fragment>Get an instant online appraisal — fast and free</Fragment>,
      svgSrc: iconAppraisal,
      cta: 'Appraise Your Car',
      to: '/appraisal/',
      ariaLabel: 'Appraise Your Car',
    },
    {
      label: 'Know Before You Buy',
      description: <Fragment>Honest reviews, rankings and video test-drive</Fragment>,
      svgSrc: iconEditorial,
      cta: 'View Reviews',
      to: '/car-reviews/',
      ariaLabel: 'View Reviews',
      filter: [BRAND_PAGE_FILTER.ALL],
    },
    {
      label: 'See Your Real Payments',
      description: <Fragment>Prequalify for financing and know your real payment</Fragment>,
      svgSrc: iconCalculator,
      cta: 'Calculate Payments',
      to: '/financing/prequalify-and-shop/intro/',
      ariaLabel: 'Calculate Payments',
    },
    {
      label: 'Get Deal Ratings on New Cars',
      description: <Fragment>Deal ratings on new listings near you</Fragment>,
      svgSrc: iconNewCars,
      cta: 'Search New Car Inventory',
      to: '/new-cars/',
      ariaLabel: 'Search New Car Inventory',
      filter: [BRAND_PAGE_FILTER.ALL],
    },
    {
      label: 'Shop Used Cars Near You',
      description: <Fragment>Shop used listings near you</Fragment>,
      svgSrc: iconLocator,
      cta: 'Search top offers for used cars',
      to: '/used-cars-for-sale/',
      ariaLabel: 'Search top offers for used cars',
    },
  ].filter(({ filter }) => !filter || filter.includes(pageFilter));
