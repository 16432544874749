import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const ToolMap = {
  tool_select_car_reviews_and_road_tests: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'car_reviews_and_road_tests'
  ),

  tool_select_monthly_payment_calculator: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'monthly_payment_calculator'
  ),

  tool_select_car_reviews: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_reviews'),

  tool_select_tips_advice: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'tips_advice'),

  tool_select_long_term_road_tests: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'long-term_road_tests'
  ),

  tool_select_car_videos: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_videos'),

  tool_select_dealer_ratings_and_reviews: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'dealer_ratings_and_reviews'
  ),

  tool_select_maintenance: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'maintenance'),

  tool_select_new_cars: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'new_cars'),

  tool_select_first_impressions: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'first_impressions'
  ),

  tool_select_calculators: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'calculators'),

  tool_select_car_loan_calculators: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'car_loan_calculators'
  ),

  tool_select_car_finder: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_finder'),

  tool_select_compare_cars: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'compare_cars'),

  tool_select_find_a_dealer: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'find_a_dealer'),

  tool_select_get_monthly_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'get_monthly_payment'
  ),

  tool_select_incentives_rebates: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'incentives_rebates'
  ),

  tool_select_true_cost_to_own: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'true_cost_to_own'),

  tool_select_used_cars: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'used_cars'),

  tool_select_appraise_your_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.APPRAISE_YOUR_CAR
  ),

  tool_select_sell_your_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SELL_YOUR_CAR
  ),

  tool_select_certified_cars: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'certified_cars'),

  tool_select_selling_tips: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'selling_tips'),

  tool_select_configure_vehicle: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'configure_vehicle'
  ),

  tool_select_car_news: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_news'),

  tool_select_automobile_forums: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    'automobile_forums'
  ),

  tool_select_car_dealerships: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_dealerships'),

  tool_select_car_financing: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_financing'),

  tool_select_car_leasing: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_leasing'),

  tool_select_buy_a_car: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'buy_a_car'),

  tool_select_sell_my_car: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'sell_my_car'),

  tool_select_car_valuation: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'car_valuation'),

  tool_select_research: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'research'),

  tool_provide_user_feedback: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_ENGAGE_SURVEY,
    TrackingConstant.COMPLETE_DELIGHTED_SURVEY
  ),
};
