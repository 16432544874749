import { createModelSegment } from 'client/data/luckdragon/segment';
import { isEmpty, orderBy, get } from 'lodash';
import { FORMATS, orderByPosition } from 'client/data/cms/content';
import { CmsModel } from 'client/data/models/cms';
import { HOME_PAGE_CONTENT_PATH } from 'site-modules/shared/constants/home-page';

const sortingAds = slide => FORMATS.truthy.test(get(slide, 'contentMetadata.adSlide', 'false'));

const resolveContentById = (context, id) =>
  context
    .resolveValue(`content["${HOME_PAGE_CONTENT_PATH}"]`, CmsModel)
    .then(content => get(content, 'childEntries', []).find(child => child.id === id));

export const HomeModulesModel = createModelSegment('homeModules', [
  {
    path: 'homeHeroContent',
    async resolve(_, context) {
      return resolveContentById(context, 'hero-carousel').then(homeHeroContent => {
        const childEntries = homeHeroContent && homeHeroContent.childEntries;

        return {
          ...homeHeroContent,
          childEntries: orderBy(orderByPosition(childEntries), sortingAds, ['desc']),
        };
      });
    },
  },
  {
    path: 'vehicleShowcaseContent',
    async resolve(_, context) {
      return resolveContentById(context, 'home-vehicle-showcase-v2').then(content => {
        if (isEmpty(content)) {
          return null;
        }
        return {
          ...content,
          childEntries: orderBy(orderByPosition(content.childEntries), sortingAds, ['desc']),
        };
      });
    },
  },
]);
