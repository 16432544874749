// body types were taken from https://qa-21-www.edmunds.com/api/inventory/v5/aggregate?by=vehicleInfo.styleInfo.bodyType&top=20
export const BODY_TYPES_SRP_EXPANDED = [
  'SUV',
  'Sedan',
  'Pickup',
  'Hatchback',
  'Coupe',
  'Minivan',
  'Convertible',
  'Station Wagon',
  'Full Size Van',
];
export const ASSIGNED_DEALER_NAMES = ['Carvana', 'Shift', 'EchoPark Automotive', 'Enterprise Car Sales'];
export const IN_STOCK_ONLINE = 'In-stock online';
export const DEFAULT_COLOR = 'Unknown';
export const VIN_PREVIEW_CREATIVE_ID = 'vdp_preview_module';
