import { CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';

export const TYPES_WITH_MOST_SOLD_GRAPH = [
  CAR_TYPES.SUV,
  CAR_TYPES.TRUCK,
  'electric-suvs',
  'hybrid-suvs',
  'small-suvs',
  'midsize-suvs',
  'large-suvs',
  'luxury-suvs',
  '3-row-suvs',
];
