import { get } from 'lodash';

/**
 * Checks if can generate lease payments
 * @param {object} vehicle
 * @returns {boolean}
 */
export function isLeasePaymentsEligible(vehicle) {
  return (
    !!get(vehicle, 'computedDisplayInfo.transparentPricingCompliance.bestDealPrice') ||
    !!get(vehicle, 'prices.displayPrice')
  );
}
