import lowerCase from 'lodash/lowerCase';

/**
 * Returns true if we should show the trim name (ie. when the trim is not "Base").
 *
 * Please note: this method is only to be used for certain scenarios. See below
 * for details.
 *
 * When to use this method:
 * • Use this method - if you are displaying trim as part of a vehicle name
 * • Do not use this method - when displaying a list of trims
 *
 * Explanation:
 *
 * There are some makes (often luxury) that do not have a name for their lowest level trim.  On
 * the data side, we manually set those to "base".  However, these OEMs prefer that we do not
 * display the word "base" when possible.  For example, on our search results cards, we would
 * normally append trim to the header of each card to help differentiate the vehicles when the
 * user is skimming the results.  However, in this case, the absence of a trim works just as well
 * for skimming. Compare the following:
 *
 * (hide trim when trim == "base")
 * Used 2008 Porsche Cayenne
 * Used 2008 Porsche Cayenne Turbo
 *
 * (show trim when trim == "base")
 * Used 2008 Porsche Cayenne Base
 * Used 2008 Porsche Cayenne Turbo
 *
 * In the first example, The user can still intuit that the "Base" vehicle has some difference
 * from the "Turbo" vehicle.
 *
 * However, we would not want to hide the word "Base" from places where we are listing out all
 * of the possible trims (ex: in the filters checkboxes or when we are listing all possible trims).
 *
 * For more details, see: https://edmunds.atlassian.net/browse/BLT-130
 *
 * @param trim
 * @returns {boolean}
 */
export function showTrimName(trim) {
  return lowerCase(trim) !== 'base';
}
