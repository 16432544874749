import PropTypes from 'prop-types';

export const BADGE = {
  TOP_RATED: 'TOP_RATED',
};

const ModelYear = PropTypes.shape({
  makeName: PropTypes.string,
  makeSlug: PropTypes.string,
  modelName: PropTypes.string,
  modelSlug: PropTypes.string,
  generations: PropTypes.arrayOf(
    PropTypes.shape({
      generation: PropTypes.number,
      generationYears: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  year: PropTypes.number,
  publicationStates: PropTypes.arrayOf(PropTypes.string),
});

const Range = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number,
});

const Badges = PropTypes.arrayOf(PropTypes.oneOf(Object.values(BADGE)));

const RankingVehicle = PropTypes.shape({
  baseStyle: PropTypes.shape({
    id: PropTypes.string,
    trim: PropTypes.shape({
      bodyType: PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
  badges: Badges,
  rank: PropTypes.number,
  modelYear: ModelYear,
  mpgRange: Range,
  priceRange: Range,
  edmundsElectricityRange: PropTypes.number,
  maxEPAElectricityRange: PropTypes.number,
  note: PropTypes.string,
  rating: PropTypes.number,
  rankedSubmodel: PropTypes.string,
  slugRankedSubmodel: PropTypes.string,
  modelDisplayOverride: PropTypes.string,
});

const SegmentRating = PropTypes.shape({
  badges: Badges,
  rank: PropTypes.number,
  slugRankedSubmodel: PropTypes.string,
  rating: PropTypes.number,
  editorialSegment: PropTypes.shape({
    displayName: PropTypes.string,
    edmundsTypeCategory: PropTypes.string,
    id: PropTypes.string,
  }),
});

const VehicleRankingDescription = {
  id: PropTypes.string,
  edmundsTypeCategory: PropTypes.string,
  description: PropTypes.string,
  displayName: PropTypes.string,
  market: PropTypes.string,
  subsegment: PropTypes.string,
};

const VehicleRanking = PropTypes.shape({
  ...VehicleRankingDescription,
  segmentRatings: PropTypes.arrayOf(RankingVehicle),
});

export const TypeRankingsEntities = {
  VehicleRanking,
  VehicleRankingDescription,
  SegmentRating,
  RankingVehicle,
  Badges,
  ModelYear,
  Range,
};
