import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import './high-impact-controls.scss';

export function HighImpactControls({ slides, updateSelectedSlide, selectedSlideIndex }) {
  return (
    <Row className="high-impact-controls px-md-3 mx-md-3" role="tablist">
      {slides.map((slideItem, index) => (
        <Col
          xs={12 / slides.length}
          key={slideItem.id}
          className="high-impact-controls-tile px-0_25 d-flex align-items-end"
          role="presentation"
        >
          <Button
            onClick={updateSelectedSlide}
            value={index}
            className={classNames('high-impact-control-button text-center w-100', {
              'selected-button': selectedSlideIndex === index,
            })}
            role="tab"
            id={`tab-${slideItem.id}`}
            aria-selected={selectedSlideIndex === index}
            aria-controls={`tabpanel-${slideItem.id}`}
          >
            <ContentFragment
              classes={classNames('advertiser-name heading-5 font-weight-normal', {
                'text-primary-darker': selectedSlideIndex === index,
              })}
              componentToUse="span"
            >
              {slideItem.metadata('advertiserName').value()}
            </ContentFragment>
          </Button>
        </Col>
      ))}
    </Row>
  );
}

HighImpactControls.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedSlide: PropTypes.func.isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
};
