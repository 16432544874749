import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import num1Bubble from 'client/images/ads/ads-9843-num-1-bubble.svg';
import num1BubbleLg from 'client/images/ads/ads-9843-num-1-bubble-lg.svg';
import mapBubble from 'client/images/ads/ads-9843-map-pin-bubble.svg';
import mapBubbleLg from 'client/images/ads/ads-9843-map-pin-bubble-lg.svg';
import authorBubble from 'client/images/ads/ads-9843-author-bubble.svg';
import authorBubbleLg from 'client/images/ads/ads-9843-author-bubble-lg.svg';
import evWithCharger from 'client/images/ads/ads-9843-ev-with-charger.svg';

import './ads-9843-hero-graphics.scss';

export function Ads9843HeroGraphics({ isChal1, isChal2 }) {
  const evRef = useRef();
  const parentRef = useRef();

  const addLoadedClassName = useCallback(() => {
    parentRef.current.classList.add('img-loaded');
    evRef.current.removeEventListener('load', addLoadedClassName, false);
  }, []);

  useEffect(() => {
    const evImg = evRef.current;

    if (isChal2) {
      if (!evImg.complete) {
        evImg.addEventListener('load', addLoadedClassName, false);
      } else {
        addLoadedClassName();
      }
    }

    return () => {
      if (isChal2) {
        evImg.removeEventListener('load', addLoadedClassName, false);
      }
    };
  }, [addLoadedClassName, isChal2]);

  return (
    <div
      className={classnames('d-flex flex-column mb-2 mb-md-0 mx-auto mr-md-0 pos-r chal-graphics', {
        'mt-3 overflow-hidden my-md-auto': isChal1,
        'mt-2_5 mt-md-2 mb-md-2 ml-md-2 ml-lg-0': isChal2,
      })}
      ref={parentRef}
    >
      <div style={{ aspectRatio: '351 / 167' }}>
        {isChal1 && (
          <img
            src="https://static.ed.edmunds-media.com/unversioned/homepage/ev-station-v3.svg"
            alt=""
            fetchpriority="high"
            width="100%"
          />
        )}
        {isChal2 && (
          <div className="pos-r animation-container">
            <picture className="m-0 pos-a w-100 h-100 num1-bubble-wrapper">
              <source media="(min-width: 1200px)" srcSet={num1BubbleLg} />
              <img src={num1Bubble} alt="" fetchpriority="high" className="pos-a num1-bubble" width={131} />
            </picture>
            <picture className="m-0 pos-a w-100 h-100 map-bubble-wrapper">
              <source media="(min-width: 1200px)" srcSet={mapBubbleLg} />
              <img src={mapBubble} alt="" fetchpriority="high" className="pos-a map-bubble" width={100} />
            </picture>
            <picture className="m-0 pos-a w-100 h-100 author-bubble-wrapper">
              <source media="(min-width: 1200px)" srcSet={authorBubbleLg} />
              <img src={authorBubble} alt="" fetchpriority="high" className="pos-a author-bubble" width={161} />
            </picture>
            <figure className="m-0 pos-a w-100 h-100 ev-wrapper">
              <img src={evWithCharger} alt="" fetchpriority="high" className="pos-a ev-with-charger" ref={evRef} />
            </figure>
          </div>
        )}
      </div>
    </div>
  );
}

Ads9843HeroGraphics.propTypes = {
  isChal1: PropTypes.bool,
  isChal2: PropTypes.bool,
};

Ads9843HeroGraphics.defaultProps = {
  isChal1: false,
  isChal2: false,
};
