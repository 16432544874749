import { isNil, get, isEmpty } from 'lodash';
import {
  VEHICLE_HISTORY_POINTS_EXPERIAN,
  getHistoryPointsExperian,
} from 'site-modules/shared/components/inventory/helpers/vehicle-history-helper';

const HISTORY_BOOLEAN_KEYS = [
  'personalUseOnly',
  'salvageHistory',
  'frameDamage',
  'lemonHistory',
  'theftHistory',
  'cleanTitle',
  'freeHistoryReport',
  'noAccidents',
];

const HISTORY_KEYS = [...HISTORY_BOOLEAN_KEYS, 'ownerText', 'accidentText', 'usageType', 'historyProvider'];

const DEFAULT_HISTORY_VAL = 'Not Provided';

/**
 * Takes object of history data and transforms it for UI and SEO schema
 * @param {Object} historyData Vehicle history data
 * @returns {Object} Transformed vehicle history data
 */
export function transformVehicleHistory(historyData) {
  const experianHistoryPoints = isEmpty(historyData) ? [] : getHistoryPointsExperian(historyData);

  return HISTORY_KEYS.reduce((result, key) => {
    let val = get(historyData, key);

    if (VEHICLE_HISTORY_POINTS_EXPERIAN[key]) {
      val = get(experianHistoryPoints.find(point => point && point.name === key), 'content', DEFAULT_HISTORY_VAL);
    } else if (isNil(val)) {
      val = DEFAULT_HISTORY_VAL;
    } else if (HISTORY_BOOLEAN_KEYS.includes(key)) {
      val = val ? 'Yes' : 'No';
    }

    return { ...result, [key]: val };
  }, {});
}
