import { identity, compact } from 'lodash';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { YEAR, RADIUS } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { NATIONWIDE } from 'site-modules/shared/constants/inventory/static-facets';
import {
  MILES,
  CURRENCY,
  MULTIPLE_VALUE,
  NO_MIN_VALUE,
  NO_MAX_VALUE,
  MPG,
  MI,
} from 'site-modules/shared/constants/inventory/facets-config';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { buildFilterPixelValue } from 'client/site-modules/shared/utils/inventory-utils/build-filter-pixel-value';

const transformByLabelType = {
  [MILES]: numberWithCommas,
  [CURRENCY]: formatPriceString,
  [MPG]: identity,
  [MI]: identity,
};

const COMBINED_RANGE_POSTFIX = { [MILES]: MILES, [MPG]: MPG, [MI]: MI };

export function buildFacetRangeFilterValue(min, max) {
  return `${parseInt(min, 10) || MULTIPLE_VALUE}-${parseInt(max, 10) || MULTIPLE_VALUE}`;
}

/**
 * Returns the string that is used by the facet "deselect" buttons that display in the
 * "Filtering By" section.
 */
export function buildCombinedRangeLabel(min, max, config) {
  const { labelType } = config;
  const transformCb = labelType ? transformByLabelType[labelType] : identity;
  const defaultMin = !min || min === MULTIPLE_VALUE ? NO_MIN_VALUE : min;
  const defaultMax = !max || max === MULTIPLE_VALUE ? NO_MAX_VALUE : max;
  const minValue = parseInt(defaultMin, 10);
  const maxValue = parseInt(defaultMax, 10);
  let result = '';
  if (minValue && maxValue) {
    result = `${transformCb(minValue)}-${transformCb(maxValue)}`;
  } else if (minValue) {
    result = `${transformCb(minValue)}${defaultMax}`;
  } else if (maxValue) {
    result = `${defaultMin}${transformCb(maxValue)}`;
  }

  return COMBINED_RANGE_POSTFIX[labelType] ? `${result} ${COMBINED_RANGE_POSTFIX[labelType]}` : result;
}

export function getFormattedUsurpLabel({ facetType, labelType, name, isMaxValue, upperBound, labelPostfix }) {
  if (facetType === YEAR) return name;
  if (facetType === RADIUS) {
    return name === NATIONWIDE ? NATIONWIDE : `Within ${name}`;
  }

  // Go from 4000 to 4
  const hasThousands = name >= 1000;
  const reducedName = hasThousands ? name / 1000 : name;

  const formattedValue = labelType === CURRENCY ? formatPriceString(reducedName) : numberWithCommas(reducedName);

  return compact([formattedValue, hasThousands && 'K', isMaxValue && !upperBound && '+', labelPostfix]).join('');
}

export function fireUpdateTrackingPixel({ type, value, name, creativeId, isDirectInputChange }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.FILTER_SEARCH_RESULT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: isDirectInputChange ? TrackingConstant.ACTION_CAUSE_USER_INPUT : TrackingConstant.SELECT_CHANGE,
      creative_id: creativeId,
      value: buildFilterPixelValue(type, value, name),
    },
  });
}
