const DELIMITER = '~';

/**
 * We are using this value to generate our quick filter for the SRP page.
 * We have a dynamo databricks job to populate a dynamo table with these pixels.
 *
 * Please do not adjust this method without verifying that the quick filters will
 * not be affected.
 *
 * @see https://edmunds.atlassian.net/browse/BLT-571 (Quick Filters Backend Work)
 * @see https://docs.google.com/document/d/1mYTK7hqlygIAp5vHdf6QapeBcW3v9AW9DL8jJauHwnc/ (RFC)
 *
 * from: https://qa-21-www.edmunds.com/gateway/api/purchasefunnel/v1/srp/inventory?dma=649&exteriorColor=%22Cosmic%20Blue%20Metallic%7C70%2C69%2C107%22&lat=37.964898&lon=-87.556864&make=honda&model=civic&zip=47713
 *
 * {
 *   "type": "exteriorColor",
 *    "values": [
 *      {
 *        "name": "Modern Steel Metallic",
 *        "value": "\"Modern Steel Metallic|51,59,72\"",
 *        "count": 47,
 *        "rgb": "51,59,72"
 *      },
 *      ...
 *   ]
 * }
 *
 * @param {string} filterName - corresponds to `type` ('exteriorColor' in example above)
 * @param {string} filterValue - corresponds to `value` ('"Modern Steel Metallic|51,59,72\"' in example above - yes it needs the double quotes)
 * @param {string} filterDisplayName - corresponds to the `name` ('Modern Steel Metallic' in the example above)
 * @param {Object} options - options for additional configuration of pixel value
 * @returns {string} - delimited by DELIMITER string
 */
export function buildFilterPixelValue(filterName = '', filterValue = '', filterDisplayName = '', options = {}) {
  const encodedFilterValue = options.encodePixelValue ? encodeURIComponent(filterValue) : filterValue;
  return `${filterName}${DELIMITER}${encodedFilterValue || filterDisplayName}${DELIMITER}${filterDisplayName}`;
}
