import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildStylesPath, VehicleModel } from 'client/data/models/vehicle';
import { PUB_STATES } from 'client/constants/pub-states';

const StylesDropdownStateToProps = {
  options: bindToPath(
    ({ make, model, submodel, year, withoutSubmodel }) => {
      if (withoutSubmodel) {
        return !!(make && model && year) && buildStylesPath({ make, model, year });
      }

      return (
        !!(year && make && model && get(submodel, 'niceId')) &&
        buildStylesPath({ year, make, model, submodel: submodel.niceId })
      );
    },
    VehicleModel,
    styles => styles.filter(({ publicationState }) => publicationState !== PUB_STATES.PRE_PROD)
  ),
};

export function StylesDropdownUI({ isStyled, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  return <SelectComponent {...restProps} />;
}

StylesDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
};

StylesDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
};

export const StylesDropdown = connectToModel(StylesDropdownUI, StylesDropdownStateToProps);
