/**
 * Getter for article review links atom path.
 * @param {string} type
 * @return {string} - full path to content atom
 */
export function getReviewLinksPath(type) {
  return `/editorial/includes/venom/review-links-${type}`;
}

/**
 * Getter for ranking data atom path.
 * @param {string} type
 * @return {string} - full path to content atom
 */
export function getRankingsContentPath(type) {
  return `/${type === 'electric-car' ? 'electric' : type}/ranking-content`;
}

/**
 * Getter for subtype data atom path
 * @param {string} type
 * @param {string} subtype
 * @returns {string} - path to content atom
 */
export function getSubtypeContentPath(type, subtype) {
  return `/type/${type}/${subtype}/index`;
}
