import React from 'react';
import PropTypes from 'prop-types';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';

export function PartnerPromoAd(props) {
  const { content, isMobile } = props;

  if (!content) {
    return null;
  }
  const { logo, image, copy, urlText, url, urlMobile, urlTargetNew, adLabel } = content;

  return (
    <a
      href={isMobile ? urlMobile : url}
      target={urlTargetNew ? '_blank' : ''}
      rel="noopener noreferrer"
      className="d-inline-block"
    >
      <div className="image-wrapper pos-r mx-auto">
        {adLabel && logo && (
          <img
            className="oem-logo pos-a"
            alt=""
            src={getStaticImageUrl(logo)}
            height={'20'}
            decoding="async"
            loading="lazy"
          />
        )}
        {image && (
          <img
            className="jellybean d-block m-auto"
            alt=""
            src={getStaticImageUrl(image)}
            decoding="async"
            loading="lazy"
          />
        )}
        {adLabel && <AdLabel styles={{ top: '0px', right: '0px' }} />}
      </div>
      <div className="size-16 font-weight-bold text-gray-darker copy">
        {copy && <ContentFragment>{copy}</ContentFragment>}
      </div>
      <div className="small text-primary-darker url">
        {urlText}
        {urlTargetNew && <i className="icon-open_in_new ml-0_25 size-10" aria-hidden />}
      </div>
    </a>
  );
}

PartnerPromoAd.propTypes = {
  content: PropTypes.shape({}),
  isMobile: PropTypes.bool,
};

PartnerPromoAd.defaultProps = {
  content: null,
  isMobile: false,
};
