import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildMakeYearSubmodelsPath, VehicleModel } from 'client/data/models/vehicle';
import { filterPubStates } from 'site-modules/shared/utils/publication-states';
import { PUB_STATES } from 'client/constants/pub-states';

export const stateToProps = {
  options: bindToPath(
    ({ make, year }) => make && year && buildMakeYearSubmodelsPath({ make, year }),
    VehicleModel,
    submodels =>
      filterPubStates(submodels, [PUB_STATES.NEW_USED, PUB_STATES.USED, PUB_STATES.NEW]).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      )
  ),
};

export function SubmodelDropdownUI({ isStyled, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  return <SelectComponent {...restProps} />;
}

SubmodelDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
};

SubmodelDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
};

export const SubmodelDropdown = connectToModel(SubmodelDropdownUI, stateToProps);
