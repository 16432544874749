import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { CmsEntities } from 'client/data/models/cms';
import { CategoryCards } from 'site-modules/shared/components/category-cards/category-cards';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

const CONTENT_GRID_SECTION_HEADING_CLASSNAME = {
  textClassName: 'heading-3',
  containerClassName: 'text-capitalize heading-3 mb-0',
};

export function HomeContentGrid({ content, isMobile }) {
  if (!(content && content.children().length)) {
    return null;
  }

  const sponsoredContents = content
    .children()
    .filter(c => c.metadata('sponsored-title').value() || c.metadata('promoLabel').value());

  return (
    <Container className="home-content-grid px-0_5" data-tracking-parent="edm-entry-home-content-grid">
      <CategoryCards
        entry={content}
        sectionHeadingClassName={CONTENT_GRID_SECTION_HEADING_CLASSNAME}
        hideMobilePromoText={false}
        enableEdmundsTitle
        enableClickTracking
        headerTag="h2"
      />
      {sponsoredContents.map((item, index) => (
        <Fragment key={item.id}>
          {!isMobile && (
            <AdUnit adName="featuredarticle" md lg xl position={`${index + 1}`} oneByOne renderWhenViewable={false} />
          )}
          <AdUnit adName="mfeaturedarticle" xs sm position={`${index + 1}`} oneByOne renderWhenViewable={false} />
        </Fragment>
      ))}
    </Container>
  );
}

HomeContentGrid.propTypes = {
  content: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
};

HomeContentGrid.defaultProps = {
  isMobile: false,
};
