import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Select, SelectPropTypes, SelectDefaultProps } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildPopularStylesPath, VehicleModel } from 'client/data/models/vehicle';
import { PUB_STATES } from 'client/constants/pub-states';

const MostPopularStylesDropdownStateToProps = {
  options: bindToPath(
    ({ make, model, submodel, year, withoutSubmodel }) => {
      if (withoutSubmodel) {
        return !!(make && model && year) && buildPopularStylesPath({ make, model, year });
      }

      return (
        !!(year && make && model && get(submodel, 'niceId')) &&
        buildPopularStylesPath({ year, make, model, submodel: submodel.niceId })
      );
    },
    VehicleModel,
    styles => styles.filter(({ publicationState }) => publicationState !== PUB_STATES.PRE_PROD)
  ),
};

function labelMostPopular(options, labelKey) {
  const newOptions = [...options];
  const mostPopular = newOptions[0];
  mostPopular[labelKey] = `${mostPopular[labelKey]} (Most Popular)`;
  return newOptions;
}

export function MostPopularStylesDropdownUI({ isStyled, options, labelKey, mostPopularStyleId, ...restProps }) {
  const SelectComponent = isStyled ? StyledSelect : Select;
  const optionsWithMostPopularFirst = options.length > 1 ? labelMostPopular(options, labelKey) : options;
  return <SelectComponent options={optionsWithMostPopularFirst} labelKey={labelKey} {...restProps} />;
}

MostPopularStylesDropdownUI.propTypes = {
  ...SelectPropTypes,
  isStyled: PropTypes.bool,
};

MostPopularStylesDropdownUI.defaultProps = {
  ...SelectDefaultProps,
  isStyled: false,
};

export const MostPopularStylesDropdown = connectToModel(
  MostPopularStylesDropdownUI,
  MostPopularStylesDropdownStateToProps
);
