import { compact, isEmpty, get, endsWith } from 'lodash';
import { isUsed } from 'site-modules/shared/utils/publication-states';
import { appendTrademarkCharacter } from 'site-modules/shared/utils/inventory-utils/append-trademark-character';

export function buildDisplayVehicle({
  makeModelSubmodelYear,
  style,
  params,
  usedPrefix = 'Used',
  showYear = true,
  showMake = true,
  showSubmodel = true,
  showTrim = false,
  trimDuplicateSubmodel = false,
  disableTrademarkCharacter = false,
  updateChevroletMakeName = false,
}) {
  if (!makeModelSubmodelYear || !params) {
    return '';
  }
  const useSubmodelName = params.submodel && showSubmodel;

  let modelName = useSubmodelName ? makeModelSubmodelYear.submodels.name : makeModelSubmodelYear.model.name;

  if (
    useSubmodelName &&
    trimDuplicateSubmodel &&
    makeModelSubmodelYear.submodels.name === makeModelSubmodelYear.model.name
  ) {
    // Remove submodel name from model name if model.name equal submodels.name
    // e.g. model.name equals 'GranTurismo Convertible' equals submodels.name
    // result is 'GranTurismo'
    modelName = modelName
      .split(' ')
      .filter(word => !params.submodel.includes(word.toLowerCase()))
      .join(' ');
  }

  let makeName = get(makeModelSubmodelYear, 'make.name', '');
  if (updateChevroletMakeName && makeName === 'Chevrolet') {
    makeName = 'Chevy';
  }

  const trimName = style && (style.trim || style.trimName);

  const labelVehicle = compact([
    (params.isUsed || isUsed(makeModelSubmodelYear.pubStates)) && usedPrefix,
    showYear && makeModelSubmodelYear.year,
    showMake && makeName,
    modelName,
    showTrim && !endsWith(modelName, trimName) && trimName,
  ]).join(' ');
  return disableTrademarkCharacter
    ? `${labelVehicle}`
    : appendTrademarkCharacter({ make: makeModelSubmodelYear.make.name, str: `${labelVehicle}` });
}

export function buildSpecificStyleDisplayVehicle({ makeModelSubmodelYear, style, params }) {
  if (isEmpty(style)) {
    return buildDisplayVehicle({ makeModelSubmodelYear, params, disableTrademarkCharacter: true });
  }
  return `${buildDisplayVehicle({
    makeModelSubmodelYear,
    params,
    showSubmodel: !style.name,
    disableTrademarkCharacter: true,
  })} ${style.name}`;
}
