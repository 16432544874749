import { isBoolean } from 'lodash';
import { getStaticImageUrl } from 'client/utils/image-helpers';

export const FREE_EXPERIAN = 'FREE_EXPERIAN';
export const PAID_EXPERIAN = 'PAID_EXPERIAN';
export const AUTOCHECK_LOGO_URL = 'img/insider/partner-deals/autocheck/autocheck-logo.png';
const EXPERIAN = 'EXPERIAN';
const displayStatusMap = {
  [FREE_EXPERIAN]: {
    url: '/:make/:model/:year/vin/:vin/history-report/?franchiseId=:franchiseId&token=:token',
    title: 'Free AutoCheck Full History Report',
    trackingId: 'free_experian',
    image: 'autocheck_logo.jpg',
  },
  [PAID_EXPERIAN]: {
    url: '//www.autocheck.com/vehiclehistory/landing?siteID=3402&vin=:vin&franchiseId=:franchiseId',
    title: 'Buy AutoCheck Full History Report',
    trackingId: 'paid_experian',
    image: 'autocheck_logo.jpg',
  },
};

export const TITLE_ISSUE = 'Title issue reported';
export const NO_HISTORY = 'noHistory';
export const OWNER_TEXT = 'ownerText';
export const ACCIDENT_TEXT = 'accidentText';
export const USAGE_TYPE = 'usageType';
export const CLEAN_TITLE = 'cleanTitle';
export const VEHICLE_HISTORY_POINTS_EXPERIAN = {
  [OWNER_TEXT]: {
    '1': {
      content: '1 Reported Owner',
      icon: 'user2',
    },
    '2': {
      content: '2 Reported Owners',
      icon: 'users3',
    },
    more: value => ({
      content: `${value} Reported Owners`,
      icon: 'users3',
    }),
    notProvided: {
      content: 'Owner count not provided',
      icon: 'question3',
    },
  },
  [ACCIDENT_TEXT]: {
    '0': {
      content: 'No Reported Accidents',
      icon: 'clipboard2',
    },
    '1': {
      content: '1 Reported Accident',
      icon: 'warning2',
    },
    more: value => ({
      content: `${value} Reported Accidents`,
      icon: 'warning2',
    }),
    notProvided: {
      content: 'Accident history not provided',
      icon: 'question3',
    },
  },
  [USAGE_TYPE]: {
    'Personal Use': {
      content: 'Personal Use Only',
      icon: 'home6',
    },
    'Livery Use': {
      content: 'Livery Vehicle',
      icon: 'icon-car2',
    },
    'Rental Use': {
      content: 'Rental Vehicle',
      icon: 'car',
    },
    'Commercial Use': {
      content: 'Commercial Vehicle',
      icon: 'office',
    },
    'Government Use': {
      content: 'Government Vehicle',
      icon: 'office',
    },
    'Taxi Use': {
      content: 'Taxi Vehicle',
      icon: 'car',
    },
    Taxi: {
      content: 'Taxi Vehicle',
      icon: 'car',
    },
    Lease: {
      content: 'Lease Vehicle',
      icon: 'home6',
    },
    Lease_SRP_expanded: {
      content: 'Personal use',
      icon: 'home6',
    },
    'Corporate Fleet': {
      content: 'Corporate Fleet Vehicle',
      icon: 'office',
    },
    Police: {
      content: 'Police Use',
      icon: 'office',
    },
    "Driver's Education": {
      content: "Driver's Education Vehicle",
      icon: 'icon-car2',
    },
    notProvided: {
      content: 'Usage history not provided',
      icon: 'question3',
    },
  },
  [CLEAN_TITLE]: {
    positive: {
      content: 'Clean Title',
      icon: 'clipboard2',
    },
    negative: {
      icon: 'warning2',
    },
    notProvided: {
      content: 'Title information not provided',
      icon: 'question3',
    },
  },
};

export const VEHICLE_HISTORY_POINTS_EXPERIAN_VDP = {
  [OWNER_TEXT]: {
    '1': {
      content: '1 Owner',
      icon: 'user2',
    },
    more: value => ({
      content: `${value} Owners`,
      icon: 'users3',
    }),
    notProvided: {
      content: 'Owner count not available',
      icon: 'question3',
    },
  },
  [ACCIDENT_TEXT]: {
    '0': {
      content: '0 Accidents',
      icon: 'clipboard2',
    },
    '1': {
      content: '1 Accident',
      icon: 'warning2',
    },
    more: value => ({
      content: `${value} Accidents`,
      icon: 'warning2',
    }),
    notProvided: {
      content: 'Accident info not available',
      icon: 'question3',
    },
  },
  [USAGE_TYPE]: {
    'Personal Use': {
      content: 'Personal Use',
      description: 'This vehicle has been reported as personal use only',
      icon: 'home6',
    },
    'Livery Use': {
      content: 'Livery Vehicle',
      description: 'The vehicle has been used "for hire" to transport people.',
      icon: 'icon-car2',
    },
    'Rental Use': {
      content: 'Rental Vehicle',
      description: 'The vehicle has been reported as used as a fleet, rental and/or lease vehicle.',
      icon: 'car',
    },
    'Commercial Use': {
      content: 'Commercial Vehicle',
      description: 'The vehicle was used for business purposes (such as a delivery truck)',
      icon: 'office',
    },
    'Government Use': {
      content: 'Government Vehicle',
      description: 'The vehicle was used by a government agency.',
      icon: 'office',
    },
    'Taxi Use': {
      content: 'Taxi Vehicle',
      description: 'The vehicle is or was used as a taxi.',
      icon: 'car',
    },
    Taxi: {
      content: 'Taxi Vehicle',
      description: 'The vehicle is or was used as a taxi.',
      icon: 'car',
    },
    Lease: {
      content: 'Lease Vehicle',
      description: 'The vehicle has been reported as used as a fleet, rental and/or lease vehicle.',
      icon: 'home6',
    },
    'Corporate Fleet': {
      content: 'Corporate Fleet Vehicle',
      description: 'The vehicle has been reported as used as a fleet, rental and/or lease vehicle.',
      icon: 'office',
    },
    Police: {
      content: 'Police Use',
      description: 'The vehicle was reported as previously used by a police agency',
      icon: 'office',
    },
    "Driver's Education": {
      content: "Driver's Education Vehicle",
      description: 'The vehicle has been used for driver education classes',
      icon: 'icon-car2',
    },
    notProvided: {
      content: 'Usage info not available',
      icon: 'question3',
    },
  },
  [CLEAN_TITLE]: {
    positive: {
      content: 'Clean Title',
      description: 'Free of major title brands and other serious problems',
      icon: 'clipboard2',
    },
    negative: {
      content: 'Issues Reported',
      icon: 'warning2',
    },
    notProvided: {
      content: 'Title info not available',
      icon: 'question3',
    },
  },
};

const NO_HISTORY_POINT = {
  name: NO_HISTORY,
  content: 'Vehicle history not provided',
  icon: 'question3',
};

export const POINT_LABEL_MAP = {
  [OWNER_TEXT]: 'Total reported owners',
  [ACCIDENT_TEXT]: 'Total reported accidents',
  [USAGE_TYPE]: 'Usage type',
  [CLEAN_TITLE]: 'Clean title status',
};

/**
 * Gets history check image.
 * @param {string} historyDisplayStatus
 * @returns {string}
 */
export function getProviderImage(historyDisplayStatus) {
  const statusMapObj = displayStatusMap[historyDisplayStatus] || {};
  const imageName = statusMapObj.image;
  return imageName ? getStaticImageUrl(`/img/used-inventory/${imageName}`) : '';
}

/**
 * Gets history check CTA.
 * @param {string} historyDisplayStatus
 * @param {string} vin
 * @param {string} franchiseId
 * @param {string} make
 * @param {string} model
 * @param {string} year
 * @param {string} token
 * @returns {object}
 */
export function getCallToAction({ historyDisplayStatus, vin, franchiseId, make, model, year, token }) {
  const callToAction = displayStatusMap[historyDisplayStatus];

  return callToAction
    ? {
        ...callToAction,
        url: callToAction.url
          .replace(':make', make)
          .replace(':model', model)
          .replace(':year', year)
          .replace(':vin', vin)
          .replace(':franchiseId', franchiseId)
          .replace(':token', token),
        image: callToAction.image ? getStaticImageUrl(`/img/used-inventory/${callToAction.image}`) : undefined,
      }
    : null;
}

/**
 * Gets correct history status.
 * @param {boolean} freeHistoryReport
 * @param {string} historyProvider
 * @returns {string}
 */
export function getCorrectStatus(freeHistoryReport, historyProvider) {
  if (historyProvider === EXPERIAN) {
    return freeHistoryReport ? FREE_EXPERIAN : PAID_EXPERIAN;
  }
  return '';
}

/**
 * Returns history status, and if it's empty then returns PAID_EXPERIAN status
 * @param {boolean} freeHistoryReport
 * @param {string} historyProvider
 * @returns {string|string}
 */
export function getHistoryStatus(freeHistoryReport, historyProvider) {
  return getCorrectStatus(freeHistoryReport, historyProvider) || PAID_EXPERIAN;
}

/**
 * Returns EXPERIAN statuses only
 * @param {boolean} freeHistoryReport
 * @returns {string}
 */
export function getExperianHistoryStatus(freeHistoryReport) {
  return freeHistoryReport ? FREE_EXPERIAN : PAID_EXPERIAN;
}

const TITLE_ISSUES_MAP = {
  'Frame damage reported': 'The structure of the vehicle may be weakened',
  'Fire damage reported': 'The vehicle has sustained damage due to a fire',
  'Salvage reported': 'Damaged beyond repair or declared a total loss by the insurer',
  'Hail damage reported': 'The vehicle was damaged significantly by hail',
  'Water damage reported': 'A vehicle that has been damaged by water or a flood and is considered salvage',
  'Junk vehicle reported': 'This vehicle has been declared a total loss and should not be titled again',
  'Lemon reported': 'Often a new vehicle that requires repeated repairs for the same defect',
  'Rebuilt vehicle reported': 'The vehicle was a salvaged vehicle that was refurbished with new or used parts',
  'Odometer issue reported': "Broken odometer, exceeding a vehicle's mechanical limits, or suspect miles",
  'Manufacturer buyback reported': 'The vehicle could not be repaired after a reasonable number of attempts',
  'Grey market vehicle reported': 'Imported vehicle that may not meet U.S. safety and/or environmental standards',
  'Insurance loss reported': 'Reported as an insurance or probable total loss due to an accident or theft',
  'Abandoned vehicle reported': 'Reported as lost, stolen, abandoned or otherwise unclaimed',
  'Theft reported': 'The vehicle was stolen from its legal owner',
  'Recycled vehicle reported': 'Vehicle was reported as scrapped or major components removed',
  'Scrap vehicle reported': 'The vehicle has been reclaimed for its metal content',
};

/**
 * Returns array of history points objects
 * @param {Array} pointsArr
 * @param {object} pointsMap
 */
function getHistoryPointsArr(pointsArr, pointsMap) {
  return pointsArr.map(({ point, value, description }) => {
    const historyPoint = pointsMap[point];
    let providedValue;

    if (isBoolean(value)) {
      providedValue = value ? 'positive' : 'negative';
    } else if (value) {
      providedValue = historyPoint[value] ? value : 'more';
    } else {
      providedValue = 'notProvided';
    }

    if (!historyPoint[providedValue]) return null;

    const { content, icon, isCarCodeLinkAvailable: hasCarCodeLink, description: descr } =
      typeof historyPoint[providedValue] === 'function'
        ? historyPoint[providedValue](value)
        : historyPoint[providedValue];
    // Use description as content if cleanTitle is false and description is not empty/null
    const historyContent = point === CLEAN_TITLE && providedValue === 'negative' ? description || TITLE_ISSUE : content;

    return {
      name: point,
      content: historyContent,
      icon,
      isProvided: !!value,
      isCarCodeLinkAvailable: hasCarCodeLink,
      description:
        point === CLEAN_TITLE && providedValue === 'negative' && description ? TITLE_ISSUES_MAP[description] : descr,
    };
  });
}

/**
 * Gets history points objects.
 * @param {object} historyInfo
 * @param {boolean} isVdp
 * @returns {object[]}
 */
export function getHistoryPointsExperian(historyInfo, isVdp = false) {
  const { ownerText, accidentText, usageType, cleanTitle, titleDescriptions } = historyInfo;
  const pointsArr = [
    { point: ACCIDENT_TEXT, value: accidentText },
    { point: OWNER_TEXT, value: ownerText },
    { point: USAGE_TYPE, value: usageType },
    { point: CLEAN_TITLE, value: cleanTitle },
  ];

  if (titleDescriptions) {
    pointsArr.splice(3, 1); // CLEAN_TITLE point needs to be removed from pointsArr when historyInfo.cleanTitle is false
    titleDescriptions.forEach(description => {
      pointsArr.push({ point: CLEAN_TITLE, value: cleanTitle, description });
    });
  }

  const historyPoints = getHistoryPointsArr(
    pointsArr,
    isVdp ? VEHICLE_HISTORY_POINTS_EXPERIAN_VDP : VEHICLE_HISTORY_POINTS_EXPERIAN
  );

  return !historyPoints.some(({ isProvided }) => isProvided) ? [NO_HISTORY_POINT] : historyPoints;
}

/**
 * Decides what function use to get history points depending on history provider
 * @param historyProvider
 * @param historyInfo
 * @param isVdp
 * @returns {Object[]}
 */
export function getHistoryPoints(historyProvider, historyInfo, isVdp = false) {
  if (historyProvider && historyProvider.includes(EXPERIAN)) {
    return getHistoryPointsExperian(historyInfo, isVdp);
  }

  return [NO_HISTORY_POINT];
}
