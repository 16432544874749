const ENGINE_TYPES_ENHANCED = {
  hybrid: { checkValue: 'hybrid', returnValue: 'Hybrid' },
  diesel: { checkValue: 'diesel', returnValue: 'Diesel' },
  'natural gas (CNG)': { checkValue: 'natural gas', returnValue: 'Natural Gas (CNG)' },
  'plug-in hybrid': { checkValue: 'plug-in hybrid', returnValue: 'Plug-In Hybrid' },
};

export function getEngineTypeValue({ model, trim, engineType }) {
  const engineTypeConf = ENGINE_TYPES_ENHANCED[engineType];

  return engineTypeConf &&
    !model.toLowerCase().includes(engineTypeConf.checkValue) &&
    !trim.toLowerCase().includes(engineTypeConf.checkValue)
    ? engineTypeConf.returnValue
    : '';
}
