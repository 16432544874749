const DEFAULT_OBJECT = {};
const NOT_AVAILABLE = 'Not Available';

function isStreetAvailable(street) {
  return street && street !== NOT_AVAILABLE;
}

/**
 * Convert address object to formatted address.
 * E.g. { zip: '97005', stateCode: 'OR', street: '12325 SW Canyon Rd', city: 'Beaverton' }
 * => 12325 SW Canyon Rd, Beaverton, OR, 97005
 * @param address
 * @returns {string}
 */
export function toDisplayFormattedAddress(address = DEFAULT_OBJECT) {
  const { zip, stateCode, street, city } = address;
  let formattedAddress = '';

  if (city && stateCode && zip) {
    formattedAddress = `${city}, ${stateCode} ${zip}`;
  }

  if (isStreetAvailable(street)) {
    formattedAddress = `${street}, ${formattedAddress}`;
  }

  return formattedAddress;
}

/**
 * Convert address object to query formatted address.
 * E.g. { stateName: 'Oregon', street: '12325 SW Canyon Rd', city: 'Beaverton' }
 * => Oregon,Beaverton,12325 SW Canyon Rd
 * @param {Object} address
 * @param {string} dealerName
 * @returns {string} encoded address
 */
export function toQueryFormattedAddress(address = DEFAULT_OBJECT, dealerName = '') {
  const { stateName, city, street } = address;
  let formattedAddress = '';

  if (stateName && city) {
    formattedAddress = `${stateName},${city}`;
  }

  if (isStreetAvailable(street)) {
    formattedAddress = `${formattedAddress},${street}`;
  }

  if (dealerName) {
    formattedAddress = `${formattedAddress},${dealerName}`;
  }

  return encodeURIComponent(formattedAddress);
}

/**
 * Convert address object to formatted address.
 * E.g. { zip: '97005', stateCode: 'OR', street: '12325 SW Canyon Rd', city: 'Beaverton' }
 * => Beaverton, OR
 * @param address
 * @returns {string} encoded address
 */
export function toShortFormattedAddress(address = DEFAULT_OBJECT) {
  const { stateCode, city } = address;
  let formattedAddress = '';
  if (city && stateCode) {
    formattedAddress = `${city}, ${stateCode}`;
  }
  return formattedAddress;
}
