import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'site-modules/shared/components/link/link';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { InventoryEntities } from 'client/data/models/inventory';

export function VdpExternalLink({
  vinData,
  children,
  onlyContent,
  className,
  trackingId,
  trackingValue,
  ariaLabelledBy,
  ariaDescribedBy,
}) {
  if (onlyContent) {
    return <div className={className}>{children}</div>;
  }

  const franchiseId = get(vinData, 'dealerInfo.franchiseId');
  const rooftopId = get(vinData, 'dealerInfo.rooftopId');

  return (
    <Link
      to={getVdpUrl(vinData)}
      className={className}
      data-tracking-id={trackingId}
      data-dealer-franchise-id={franchiseId}
      data-dealer-rooftop-id={rooftopId}
      data-tracking-value={trackingValue}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      {children}
    </Link>
  );
}

VdpExternalLink.propTypes = {
  vinData: InventoryEntities.InventoryVin.isRequired,
  children: PropTypes.node.isRequired,
  onlyContent: PropTypes.bool,
  className: PropTypes.string,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
};

VdpExternalLink.defaultProps = {
  onlyContent: false,
  className: '',
  trackingId: 'view_vehicle',
  trackingValue: '',
  ariaLabelledBy: null,
  ariaDescribedBy: null,
};
