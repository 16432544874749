import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';

import { getS3MediaDefaultImageUrlBySize } from 'client/utils/image-helpers';
import { Link } from 'site-modules/shared/components/link/link';
import { TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { CoreImg, NO_VEHICLE_IMAGE, NoVehicleImg } from 'site-modules/shared/components/core-img/core-img';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import {
  REPLACE_WITH_FETCHPRIORITY,
  REPLACE_WITH_IMAGE,
} from 'site-modules/shared/constants/upper-funnel/type-rankings';

import './vehicle-image.scss';

export function VehicleImage({
  imageClassName,
  imageWrapperClasses,
  imageLinkClasses,
  modelYear,
  submodelSlug,
  coreLink,
  widthToFit,
  heightToFit,
  renderWhenViewable,
  attrHeight,
  attrWidth,
  style,
  isReplaceImage,
}) {
  const { year } = modelYear;
  const modelSlug = get(modelYear, 'model.slug') || get(modelYear, 'modelSlug');
  const modelName = get(modelYear, 'model.name') || get(modelYear, 'modelName');
  const makeName = get(modelYear, 'model.make.name') || get(modelYear, 'makeName');
  const makeSlug = get(modelYear, 'model.make.slug') || get(modelYear, 'makeSlug');
  const photoVehicle = {
    make: {
      slug: makeSlug,
    },
    model: {
      slug: modelSlug,
    },
    submodels: {
      slug: submodelSlug,
    },
    year,
  };
  const vehicleKey = [makeSlug, modelSlug, year].join('/');
  const s3DefaultPhoto =
    (isReplaceImage && REPLACE_WITH_IMAGE[vehicleKey]) ||
    getS3MediaDefaultImageUrlBySize(photoVehicle, widthToFit, heightToFit);
  const vehicleDisplayName = `${year} ${makeName} ${modelName}`;

  const isFetchpriority = isReplaceImage && REPLACE_WITH_FETCHPRIORITY.includes(vehicleKey);

  const coreImg = (
    <CoreImg
      className={classnames('card-photo text-center mx-0 mx-md-auto', imageWrapperClasses)}
      imageClassName={imageClassName}
      image={s3DefaultPhoto}
      source="media"
      clickable={false}
      enableOnError
      onErrorImage={NO_VEHICLE_IMAGE}
      isLazyLoad={!isFetchpriority}
      isFetchpriority={isFetchpriority}
      attrWidth={attrWidth.toString()}
      attrHeight={attrHeight.toString()}
      style={style}
    />
  );

  const imageComponent = renderWhenViewable ? (
    <RenderWhenViewable
      verticalOffset="50%"
      placeholder={<NoVehicleImg isLazyLoad className="text-center" attrWidth={'175'} attrHeight={'117'} />}
    >
      {coreImg}
    </RenderWhenViewable>
  ) : (
    coreImg
  );

  return coreLink ? (
    <Link
      to={coreLink}
      rel="nofollow"
      className={classnames('vehicle-image-link d-flex d-md-block', imageLinkClasses)}
      data-tracking-id="veh_card_core_page_nav"
      data-tracking-value="model_image"
      aria-label={vehicleDisplayName}
    >
      {imageComponent}
    </Link>
  ) : (
    imageComponent
  );
}

VehicleImage.propTypes = {
  modelYear: TypeRankingsEntities.ModelYear.isRequired,
  imageClassName: PropTypes.string,
  imageWrapperClasses: PropTypes.string,
  imageLinkClasses: PropTypes.string,
  submodelSlug: PropTypes.string,
  coreLink: PropTypes.string,
  widthToFit: PropTypes.number,
  heightToFit: PropTypes.number,
  renderWhenViewable: PropTypes.bool,
  attrWidth: PropTypes.number,
  attrHeight: PropTypes.number,
  style: PropTypes.shape({}),
  isReplaceImage: PropTypes.bool,
};

VehicleImage.defaultProps = {
  imageClassName: '',
  imageWrapperClasses: '',
  imageLinkClasses: 'align-items-center',
  submodelSlug: null,
  coreLink: '',
  widthToFit: 300,
  heightToFit: 200,
  renderWhenViewable: false,
  attrWidth: 175,
  attrHeight: 117,
  style: undefined,
  isReplaceImage: false,
};
