import { get } from 'lodash';
/* Constants */
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';
/* Utils */
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';

/**
 * Checks if Deal Check can be shown.
 * @param {object} vehicle
 * @return {boolean}
 */
export const isUsedDealCheckAvailable = vehicle => {
  const displayPrice = get(vehicle, 'prices.displayPrice');
  const ctas = get(vehicle, 'computedDisplayInfo.ctas', []);
  const { maxGreatPrice, maxGoodPrice, maxFairPrice } = get(vehicle, 'computedDisplayInfo.priceValidation', {});

  return !!(
    isUsed(vehicle.type) &&
    displayPrice &&
    isPartnerDealer(vehicle) &&
    (maxGreatPrice || maxGoodPrice || maxFairPrice) &&
    ctas.length &&
    ctas.some(cta => cta !== CTA_TYPE.CALL_DEALER)
  );
};

export const isUsedNonPartnerDealerDealCheckAvailable = vehicle => {
  const displayPrice = get(vehicle, 'prices.displayPrice');
  const { maxGreatPrice, maxGoodPrice, maxFairPrice } = get(vehicle, 'computedDisplayInfo.priceValidation', {});

  return !!(
    isUsed(vehicle.type) &&
    displayPrice &&
    !isPartnerDealer(vehicle) &&
    (maxGreatPrice || maxGoodPrice || maxFairPrice)
  );
};
