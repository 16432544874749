import { get } from 'lodash';
import { isPickupType } from 'site-modules/shared/utils/is-pickup-type';

/**
 * Checks inventory bodyType & returns cabType for 'Pickup' listing
 * @param  {Object} styleInfo
 * @param  {Boolean} isSrpExpanded
 * @return {string}
 */
export function getCabType(styleInfo, isSrpExpanded = false) {
  let cabType = '';
  const isPickup = isPickupType(get(styleInfo, 'bodyType', ''));
  const vehicleManufacturerCabType = get(styleInfo, 'manufacturerCabType', '');
  const vehicleStyle = get(styleInfo, 'vehicleStyle', '');

  if (isPickup) {
    const vehicleCabType = vehicleStyle && vehicleStyle.replace(/(pickup)$/i, '').trim(); // remove trailing 'Pickup' string from vehicleStyle
    cabType = vehicleManufacturerCabType || vehicleCabType;
  } else if (vehicleStyle && isSrpExpanded) {
    return vehicleStyle.trim();
  }

  return cabType;
}
