import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const AuthenticatedModalMap = {
  authenticated_modal_close: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.NAVIGATION_CLOSE
  ),
  authenticated_modal_sign_in: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SIGN_IN,
    TrackingConstant.ACTION_SIGN_IN
  ),
  authenticated_modal_sign_up: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SIGN_UP,
    TrackingConstant.ACTION_SIGN_UP
  ),
};
