import React, { Fragment } from 'react';
import Container from 'reactstrap/lib/Container';
import { Link } from 'site-modules/shared/components/link/link';
import { CmsEntities } from 'client/data/models/cms';

export function HomeAnnouncements({ content }) {
  if (!(content && content.children().length)) {
    return null;
  }
  const announcementLinks = content.childEntries;

  return (
    <Container>
      <div className="home-hero-announcements medium mt-0_5 mt-md-0 px-0_5 pb-md-1_5">
        <span>{content.title}</span>
        <div className="d-inline-block">
          {announcementLinks.map((entry, index) => (
            <Fragment key={entry.id}>
              <Link
                to={entry.contentMetadata.url}
                className="seo-link d-inline-block pr-0_25 text-primary-darker"
                {...entry.attributes}
                data-tracking-id={'home_hero_seo_link'}
              >
                {entry.title}
              </Link>
              {index < announcementLinks.length - 1 && <span className="divider pr-0_25 text-gray-dark">|</span>}
            </Fragment>
          ))}
        </div>
      </div>
    </Container>
  );
}

HomeAnnouncements.propTypes = {
  content: CmsEntities.Content.isRequired,
};
