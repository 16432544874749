/**
 * Appends a trademark symbol to specific text required by compliance rules.
 *
 * For more details, see: https://edmunds.atlassian.net/browse/BLT-768
 *
 * @param make - a string representing the make of the vehicle
 * @param str - string where the trademark symbol ® might need to be appended
 * @returns {string}
 */
export function appendTrademarkCharacter({ make = '', str = '' }) {
  if (!/Mercedes-Benz/i.test(make)) {
    // short-circuit, so we aren't doing this logic when it's not necessary
    return str;
  }

  /*
   * regex - replace(/(AMG|4MATIC)/g, '$1®')
   *
   * examples:
   * 'The AMG vehicle.' -> 'The AMG® vehicle.'
   * 'The 4MATIC vehicle.' -> 'The 4MATIC® vehicle.'
   * 'The AMG 4MATIC vehicle is a 4MATIC AMG.' -> 'The AMG® 4MATIC® vehicle is a 4MATIC® AMG®.'
   */
  return str.replace(/(AMG|4MATIC)/g, '$1®');
}
