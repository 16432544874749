import React from 'react';
import PropTypes from 'prop-types';

import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

const isUrlTargetNew = slideContent =>
  slideContent.hasMetadata('urlTargetNew') && slideContent.metadata('urlTargetNew').boolean();

function getLinkClickTrackingId(slideContent) {
  return isUrlTargetNew(slideContent) ? 'hero_module_link_click_out' : 'hero_module_link_click';
}

export function InventoryCardHighlights({ position, content, isMobile }) {
  const contentChildren = content.children();
  const eligibleChildren = contentChildren.filter(child => child.metadata('imageHighlights').value());
  const heroSlide = eligibleChildren[0];

  if (!heroSlide) {
    return null;
  }

  const linkoutMetaKey = isMobile ? 'urlMobile' : 'url';
  const imageUrl = getStaticImageUrl(heroSlide.metadata('imageHighlights').value());
  const verbiage = heroSlide.metadata('verbiageHighlights').value() || heroSlide.metadata('verbiage').value();
  const footer = heroSlide.metadata('urlText').value('Learn More');
  const verbiageNoMarkup = heroSlide.metadata('verbiageNoMarkup').value();
  const verbiageSlug = verbiageNoMarkup.replace(/ /g, '-');

  const typeId = `type-${verbiageSlug}`;
  const labelId = `lnk-${verbiageSlug}`;
  const describeId = `desc-${verbiageSlug}`;

  return (
    <a
      className="inventory-card card rounded mr-1_5 mr-md-1_75 my-0_5"
      data-tracking-id={getLinkClickTrackingId(heroSlide)}
      data-tracking-value={`highlights_${verbiageNoMarkup}`}
      data-tracking-position={`${position}`}
      aria-labelledby={`${typeId} ${labelId}`}
      aria-describedby={`${describeId}`}
      href={heroSlide.metadata(linkoutMetaKey).value()}
      target={isUrlTargetNew(heroSlide) ? '_blank' : null}
      rel={isUrlTargetNew(heroSlide) ? 'noopener noreferrer' : 'nofollow'}
    >
      <AspectRatioContainer aspectRatio="3:2">
        <img
          data-test="image"
          src={imageUrl}
          alt=""
          className=""
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
      </AspectRatioContainer>
      <div className="p-0_75">
        <div className="d-flex justify-content-between mb-0_75 align-items-center first-line">
          <div className="size-10 text-primary-darker text-uppercase" id={typeId}>
            New
          </div>
        </div>
        <span className="sr-only" id={labelId}>
          {verbiage}
        </span>
        <div className="d-flex justify-content-between mb-0_75 medium font-weight-bold" aria-hidden="true">
          <div className="vehicle-name w-100">{verbiage}</div>
        </div>
        <div className="d-flex justify-content-end small text-gray-dark" id={describeId}>
          <div className="text-right last-line d-flex align-items-end">{footer}</div>
        </div>
      </div>
    </a>
  );
}

InventoryCardHighlights.propTypes = {
  position: PropTypes.number,
  content: CmsEntities.Content,
  isMobile: PropTypes.bool,
};

InventoryCardHighlights.defaultProps = {
  position: 2,
  content: DEFAULT_CONTENT,
  isMobile: false,
};
