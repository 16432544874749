import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import classnames from 'classnames';
import { times } from 'lodash';

import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

import { ShopByTypeCard } from './shop-by-type-card';
import './shop-by-type.scss';

const PATH_TO_IMAGE = '/homepage/shop-by-type/';

const TYPE_CARD_DATA = [
  {
    title: 'EV',
    trackingValue: 'Electric Cars',
    path: 'electric-car',
    icon: `${PATH_TO_IMAGE}EV-blue.svg`,
  },
  {
    title: 'SUV',
    trackingValue: 'SUVs',
    path: 'suv',
    icon: `${PATH_TO_IMAGE}SUV-blue.svg`,
  },
  {
    title: 'Truck',
    trackingValue: 'Trucks',
    path: 'truck',
    icon: `${PATH_TO_IMAGE}Truck-blue.svg`,
  },
  {
    title: 'Sedan',
    trackingValue: 'Sedans',
    path: 'sedan',
    icon: `${PATH_TO_IMAGE}Sedan-blue.svg`,
  },
  {
    title: 'Hybrid',
    trackingValue: 'Hybrids',
    path: 'hybrid',
    icon: `${PATH_TO_IMAGE}Hybrid-blue.svg`,
  },
];

const TAKEOVER_ADS_PROPS = {
  // desktop
  false: { adName: 'takeover', md: true, lg: true, xl: true },
  // mobile
  true: { adName: 'mtakeover', xs: true, sm: true },
};

export function ShopByType({ className, isMobile }) {
  return (
    <div className={classnames('shop-by-type', className)} data-tracking-parent="edm-entry-type-center">
      <h2 className="text-capitalize heading-4 mb-1_5" id="shop-by-type-heading">
        Research by type
      </h2>
      <Row
        tag="ul"
        className="shop-by-type-list flex-nowrap list-unstyled mb-1_5 type-list px-md-0 px-lg-3"
        aria-labelledby="shop-by-type-heading"
      >
        {TYPE_CARD_DATA.map(data => (
          <ShopByTypeCard key={data.title} {...data} />
        ))}
      </Row>
      {times(TYPE_CARD_DATA.length, i => (
        <AdUnit key={i} {...TAKEOVER_ADS_PROPS[isMobile]} position={`${i + 1}`} renderWhenViewable={false} oneByOne />
      ))}
    </div>
  );
}

ShopByType.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

ShopByType.defaultProps = {
  className: null,
  isMobile: false,
};
