import { get } from 'lodash';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

/**
 * Returns true if type is new, dealer price exists and make is not in MAKES_WITHOUT_DEALER_PRICE
 * @param {object} inventory
 * @returns {boolean}
 */
export function isDealerPriceAvailable(inventory) {
  const dealerPrice = get(inventory, DATA_PATH.DEALER_PRICE);
  const isNewType = isNew(get(inventory, 'type'));
  return isNewType && !!dealerPrice;
}
