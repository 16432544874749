import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import Container from 'reactstrap/lib/Container';
import { SMART_SHOPPING_ITEMS, BRAND_PAGE_FILTER } from 'site-modules/shared/constants/brand-alignment';
import { Link } from 'site-modules/shared/components/link/link';
import { Image } from 'site-modules/shared/components/image/image';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './smart-shopper.scss';

const MX_WIDTH = 352;
const MAX_WIDTH = `${MX_WIDTH}px`;
const INNER_WIDTH = 253;
const HEIGHT = '436px';
const CONTENT_WIDTH = `${INNER_WIDTH}px`;
const DEBOUNCE_DELAY = 400;
const SCROLL_DELAY = 300;

export function SmartShopper({ pageFilter, isMobile }) {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isScollBeginning, setIsScrollBeginning] = useState(true);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [count, setCount] = useState(0);
  const ref = useRef();
  const [setScrollTimeout] = useTimeout();

  function setScrollState(scrollerRef) {
    if (scrollerRef.scrollLeft + scrollerRef.offsetWidth >= scrollerRef.scrollWidth - 16) {
      setIsScrollEnd(true);
    } else {
      setIsScrollEnd(false);
    }
    if (scrollerRef.scrollLeft < 16) {
      setIsScrollBeginning(true);
    } else {
      setIsScrollBeginning(false);
    }

    setCount(Math.round(scrollerRef.scrollLeft / (MX_WIDTH - 16)));
  }

  const handleScroll = useCallback(
    options => {
      const scrollerRef = ref.current;
      // if (options) {
      const direction = options.prev ? -1 : 1;
      const deltaOnClick =
        direction > 0 ? MX_WIDTH * direction : -(scrollerRef.scrollLeft % MX_WIDTH) || MX_WIDTH * direction;
      const deltaLeft = options.delta || deltaOnClick;
      scrollerRef.scrollBy({
        top: 0,
        left: deltaLeft,
        behavior: 'smooth',
      });
      // }
      setScrollTimeout(() => {
        setScrollState(scrollerRef);
      }, SCROLL_DELAY + 100);
    },
    [setScrollTimeout]
  );

  function addScrollSnap() {
    ref.current.classList.add('scroll-snap');
  }

  const handleExpandCard = useCallback(
    e => {
      const { target } = e;
      const scrollerRef = ref.current;
      const li = target.closest('li');
      const index = Array.from(scrollerRef.querySelectorAll('li')).indexOf(li);
      setCount(index);
      setScrollTimeout(() => {
        const liRect = li.getBoundingClientRect();
        const liStartX = liRect.x;
        const liEndX = liStartX + liRect.width + 1;
        const scrollerRect = scrollerRef.getBoundingClientRect();
        const scrollerRectWidth = scrollerRect.width;
        const scrollerRectStartX = scrollerRect.x;
        const scrollerRectEndX = scrollerRectStartX + scrollerRectWidth;
        const willCutOffOnRight = liEndX > scrollerRectEndX;
        const willCutOffOnLeft =
          (!isScollBeginning && liEndX - MX_WIDTH - scrollerRectStartX < 0) || liStartX < scrollerRectStartX;
        const delay = e.type === 'focus' ? 75 : 0;
        setScrollTimeout(() => {
          scrollerRef.classList.remove('scroll-snap');
        }, delay);

        if (willCutOffOnLeft || willCutOffOnRight) {
          const scrollByLeft = liStartX - scrollerRectStartX;
          const scrollByRight = liStartX + MX_WIDTH - scrollerRectEndX;
          const delta = willCutOffOnLeft ? scrollByLeft : scrollByRight;
          handleScroll({ delta });
        }
      }, SCROLL_DELAY);
    },
    [handleScroll, isScollBeginning, setScrollTimeout]
  );

  const handleWheelEvent = debounce(() => {
    setScrollState(ref.current);
  }, DEBOUNCE_DELAY);

  function onPrevClicked() {
    addScrollSnap();
    handleScroll({ prev: true });
  }

  function onNextClicked() {
    addScrollSnap();
    handleScroll({ next: true });
  }

  useEffect(() => {
    if (window.matchMedia('(pointer: coarse)').matches) {
      setIsTouchDevice(true);
    }
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    const focusableAnchors = currentRef.querySelectorAll('a');
    currentRef.addEventListener('wheel', handleWheelEvent, false);
    currentRef.addEventListener('mouseover', handleExpandCard, false);
    focusableAnchors.forEach(anchor => {
      anchor.addEventListener('focus', handleExpandCard, false);
    });

    return function cleanup() {
      currentRef.removeEventListener('wheel', handleWheelEvent, false);
      currentRef.removeEventListener('mouseover', handleExpandCard, false);
      focusableAnchors.forEach(anchor => {
        anchor.removeEventListener('focus', handleExpandCard, false);
      });
    };
  }, [handleExpandCard, handleWheelEvent]);

  return (
    <section
      className={`pos-r mx-auto count-${count}`}
      style={{
        maxWidth: '1600px',
      }}
    >
      <div className="bubble-purple purple-1 pos-a" />
      <div className="bubble-purple purple-2 pos-a d-none d-md-block" />
      <div className="bubble-purple purple-3 pos-a" />
      <div className="bubble-blue blue-1 pos-a" />
      <div className="bubble-blue blue-2 pos-a" />
      <div className="bubble-blue blue-3 pos-a" />
      <Container tag="article">
        <section className="smart-shopper" data-tracking-parent="edm-entry-brand-module">
          <header className="mb-1_75">
            <h2 className="text-capitalize heading-2 mb-0_75">
              <span className={classnames({ 'd-block d-md-inline-block': isTouchDevice })}>Shoppers go to </span>{' '}
              <span className="gradient-text">Edmunds first</span>
            </h2>
            <div className="size-20 text-gray-darker">Resources for every car shopper - all in one place</div>
          </header>
          <article
            className="scroll-container scroll-snap py-0_5"
            ref={ref}
            style={
              isMobile
                ? {
                    overflowX: 'scroll',
                    scrollPaddingLeft: '1rem',
                    width: 'calc(100vw + 16px)',
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }
                : {
                    overflowX: 'scroll',
                    marginLeft: '-8px',
                  }
            }
          >
            <ul
              className="list-unstyled px-1 px-lg-0 mb-0 mx-auto text-white"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {SMART_SHOPPING_ITEMS(pageFilter).map(({ label, description, svgSrc, cta, to, ariaLabel }) => (
                <li
                  key={label}
                  className={classnames('pos-r d-inline-block', {
                    'is-mouse-device': !isTouchDevice,
                  })}
                  style={{
                    maxWidth: MAX_WIDTH,
                    height: HEIGHT,
                    whiteSpace: 'normal',
                  }}
                >
                  <Link
                    to={to}
                    rel="nofollow"
                    data-tracking-id="view_content"
                    aria-label={ariaLabel}
                    className="d-block h-100 mr-0_5 mx-auto"
                    style={{
                      width: 'calc(100% - 1rem)',
                    }}
                  >
                    <Image imageId={svgSrc} source="" alt="" lazy className="pos-a right-0 mt-1_5 svg-icon" />
                    <p className="d-flex flex-column h-100 pb-1_5 px-1 pos-a top-0">
                      <ContentFragment
                        classes="font-weight-bold d-block mt-auto size-30 label-heading"
                        inlineStyle={{ width: CONTENT_WIDTH }}
                        componentToUse="span"
                      >
                        {label}
                      </ContentFragment>
                      <span
                        className={classnames('d-block pr-1 description mt-0_75 size-16', {
                          'pr-0_75': isTouchDevice,
                          'pr-2_5': !isTouchDevice,
                        })}
                        style={isTouchDevice ? null : { width: 'inherit' }}
                      >
                        {description}
                      </span>
                      <span
                        className="d-block px-0_75 py-0_5 cta mt-1_25 font-weight-medium"
                        style={{
                          width: 'min-content',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {cta}
                      </span>
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </article>
          <div className="mt-1_75 mb-2_5 pb-2 pr-0_75 d-flex justify-content-end">
            <button
              className="card-scroll-btn p-0_5 border-0 d-flex align-items-center"
              onClick={onPrevClicked}
              disabled={isScollBeginning ? true : null}
              style={{
                width: '2rem',
                height: '2rem',
              }}
              aria-label="previous card"
            >
              <span className="icon-arrow-left3 text-cool-gray-30" aria-hidden />
            </button>
            <button
              className="card-scroll-btn p-0_5 border-0 d-flex align-items-center ml-0_5"
              onClick={onNextClicked}
              disabled={isScrollEnd ? true : null}
              style={{
                width: '2rem',
                height: '2rem',
              }}
              aria-label="next card"
            >
              <span className="icon-arrow-right3 text-cool-gray-30" aria-hidden />
            </button>
          </div>
        </section>
      </Container>
    </section>
  );
}

SmartShopper.propTypes = {
  pageFilter: PropTypes.oneOf(Object.values(BRAND_PAGE_FILTER)),
  isMobile: PropTypes.bool,
};

SmartShopper.defaultProps = {
  pageFilter: undefined,
  isMobile: false,
};
