export const TrackingEvent = {
  /**
   * Main tracking action entry generator, cannot be used directly since of predefined event types
   * Use trackAction[eventType] methods instead, e.g. actionProgress
   *
   * @param {String} type
   * @param {String} actionName
   * @param {String} subActionName
   * @param [overrides]
   * @return {{event_type: *, event_data}}
   */
  trackAction(type, actionName, subActionName, overrides) {
    const eventData = {
      action_name: actionName,
      subaction_name: subActionName,
      action_category: 'user',
      ...overrides,
    };

    return {
      event_type: type,
      event_data: eventData,
    };
  },

  /**
   * Event type: action_completed
   *
   * @param {String} actionName
   * @param {String} subActionName
   * @param {{}} [overrides]
   * @return {*|{event_type, event_data}}
   */
  actionCompleted(actionName, subActionName, overrides) {
    return this.trackAction('action_completed', actionName, subActionName, overrides);
  },

  /**
   * Event type: action_start
   *
   * @param {String} actionName
   * @param {String} subActionName
   * @param {{}} [overrides]
   * @return {*|{event_type, event_data}}
   */
  actionStart(actionName, subActionName, overrides) {
    return this.trackAction('action_start', actionName, subActionName, overrides);
  },

  /**
   * Event type: action_progress
   *
   * @param {String} actionName
   * @param {String} subActionName
   * @param {{}} [overrides]
   * @return {*|{event_type, event_data}}
   */
  actionProgress(actionName, subActionName, overrides) {
    return this.trackAction('action_progress', actionName, subActionName, overrides);
  },

  /**
   * Event type: action_end
   *
   * @param {String} actionName
   * @param {String} subActionName
   * @param {{}} [overrides]
   * @return {*|{event_type, event_data}}
   */
  actionEnd(actionName, subActionName, overrides) {
    return this.trackAction('action_end', actionName, subActionName, overrides);
  },
};
