import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Container from 'reactstrap/lib/Container';
import { orderBy, times } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { positionEntries } from 'client/data/cms/content';
import { fireHighImpactTabSelect } from 'client/engagement-handlers/home-engagement-handler/home-engagement-handler';
import { HighImpactContent } from 'site-modules/upper-funnel/components/high-impact/high-impact-content';
import { HighImpactControls } from 'site-modules/upper-funnel/components/high-impact/high-impact-controls';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

/* Support max of 4 sold ads */
const MAX_ADS = 4;

function getSlides(contentChildren) {
  return orderBy(positionEntries(contentChildren), slide => slide.metadata('adSlide').boolean(), ['desc']);
}
export function HighImpact({ content }) {
  const contentChildren = content && content.children();

  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  // Only randomize sold ads
  const [slides, setSlides] = useState(getSlides(contentChildren));

  const updateSelectedSlide = useCallback(
    event => {
      const newSlideIndex = parseInt(event.currentTarget.value, 10);
      if (newSlideIndex !== selectedSlideIndex) {
        fireHighImpactTabSelect({
          actionCause: 'click',
          carouselIndex: newSlideIndex,
          carouselPrevIndex: selectedSlideIndex,
          carouselTotal: MAX_ADS,
          trackingValue: slides[newSlideIndex].metadata('advertiserName').value(),
          creativeId: 'edm-entry-home-high-impact',
        });
        setSelectedSlideIndex(newSlideIndex);
      }
    },
    [selectedSlideIndex, slides]
  );

  useEffect(() => {
    setSlides(getSlides(contentChildren));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentChildren.length]);

  if (!slides.length) {
    return null;
  }

  return (
    <Container data-tracking-parent="edm-entry-home-high-impact" className="high-impact px-0 mx-0 mx-auto w-100">
      <RenderWhenViewable verticalOffset="50%">
        <Container>
          <HighImpactControls
            slides={slides}
            selectedSlideIndex={selectedSlideIndex}
            updateSelectedSlide={updateSelectedSlide}
          />
        </Container>
        <Container fluid className="high-impact-slides-container w-100 pb-3 px-0 px-lg-0_5">
          <HighImpactContent slides={slides} selectedSlideIndex={selectedSlideIndex} />
        </Container>
      </RenderWhenViewable>
      {times(MAX_ADS, i => (
        <Fragment key={i}>
          <AdUnit adName="highimpact" md lg xl position={`${i + 1}`} oneByOne renderWhenViewable={false} />
        </Fragment>
      ))}
    </Container>
  );
}

HighImpact.propTypes = {
  content: CmsEntities.Content.isRequired,
};
