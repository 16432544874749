import { get } from 'lodash';
/**
 * Tracking helper
 *
 * @type {Object}
 */
export const TrackingHelper = {
  getBasicDealerOptions(options, includeInventory) {
    let franchiseId;
    let rooftopId;
    let inventoryId;

    if (options && options.currentEl) {
      franchiseId = options.currentEl.getAttribute('data-dealer-franchise-id');
      rooftopId = options.currentEl.getAttribute('data-dealer-rooftop-id');
    }

    if (!franchiseId) {
      franchiseId = get(options, 'state.inventory.dealer.franchiseId');
    }

    if (!rooftopId) {
      rooftopId = get(options, 'state.inventory.dealer.rooftopId');
    }

    const trackingData = {
      loc_id: rooftopId,
      f_id: franchiseId,
    };

    if (includeInventory) {
      inventoryId = options.currentEl.getAttribute('data-inventory-id');
      trackingData.inv_id = inventoryId;
    }

    if (franchiseId && options.setPartnerId) {
      trackingData.partner_id = franchiseId;
    }

    return trackingData;
  },
  /**
   * Gets subactions name from element data attr.
   * @param options
   * @returns {string}
   */
  getSubactionName(options) {
    return (options && options.currentEl && options.currentEl.getAttribute('data-subaction-name')) || '';
  },
};
