import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { AuthenticatedModalMap } from 'client/tracking/maps/insider/authenticated-modal';

export const NavMap = {
  homepage_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.ACTION_RESET_NAVIGATION
  ),

  nav_lease_deals_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_LEASE_DEALS
  ),

  nav_mmy_select_tool_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_TOOL_SELECT
  ),

  nav_mmy_select_new_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.NEW_CAR_SELECT
  ),

  nav_mmy_select_used_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.USED_CAR_SELECT
  ),

  nav_mmy_select_new_car_start: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.NEW_CAR_SELECT
  ),

  nav_mmy_select_used_car_start: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.USED_CAR_SELECT
  ),

  nav_mmy_select_make: TrackingEvent.actionProgress(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.MAKE_SELECT),

  nav_mmy_model_select: TrackingEvent.actionProgress(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.MODEL_SELECT),

  nav_mmy_year_select: TrackingEvent.actionEnd(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.YEAR_SELECT),

  nav_mmy_select_submit_make: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MAKE
  ),

  nav_mmy_select_car_types: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.CAR_TYPES
  ),

  nav_mmy_select_cars_for_sale: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.CARS_FOR_SALE
  ),

  nav_mmy_select_research: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.RESEARCH),

  nav_type_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.ACTION_TYPE_SELECT
  ),

  nav_inventory_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.ACTION_INVENTORY_SELECT
  ),

  nav_inventory_select_new_used: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.NEW_USED_CAR_SELECT
  ),

  nav_inventory_select_type: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.ACTION_TYPE_SELECT
  ),

  nav_inventory_select_make: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.MAKE_SELECT
  ),

  nav_access_my_account: TrackingEvent.actionCompleted(
    TrackingConstant.ACCESS_MY_ACCOUNT,
    TrackingConstant.ACCESS_MY_ACCOUNT
  ),

  authenticate_social_start: TrackingEvent.actionStart(
    TrackingConstant.ACTION_SIGN_IN,
    TrackingConstant.AUTHENTICATE_SOCIAL_START
  ),

  authenticate_email: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SIGN_IN,
    TrackingConstant.AUTHENTICATE_EMAIL
  ),

  success_authenticate_email: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_SIGN_IN,
    TrackingConstant.SUCCESS_AUTHENTICATE_EMAIL
  ),

  recover_password: TrackingEvent.actionStart(TrackingConstant.RECOVER_PASSWORD, TrackingConstant.RECOVER_PASSWORD),

  sign_in_password: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SIGN_IN,
    TrackingConstant.ACTION_SIGN_IN_PASSWORD
  ),

  sign_in_link: TrackingEvent.actionProgress(TrackingConstant.ACTION_SIGN_IN, TrackingConstant.ACTION_SIGN_IN_LINK),

  submit_forgot_password: TrackingEvent.actionProgress(
    TrackingConstant.RECOVER_PASSWORD,
    TrackingConstant.SUBMIT_FORGOT_PASSWORD
  ),

  provide_first_name: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SIGN_UP,
    TrackingConstant.PROVIDE_FIRST_NAME
  ),

  provide_last_name: TrackingEvent.actionProgress(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.PROVIDE_LAST_NAME),

  provide_email: TrackingEvent.actionProgress(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.PROVIDE_EMAIL),
  provide_email_start: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.PROVIDE_EMAIL),

  provide_password: TrackingEvent.actionProgress(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.PROVIDE_PASSWORD),

  begin_sign_up: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.BEGIN_SIGN_UP),

  submit_sign_up: TrackingEvent.actionProgress(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.SUBMIT_SIGN_UP),

  submit_sign_up_email: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SIGN_UP,
    TrackingConstant.SUBMIT_SIGN_UP_EMAIL
  ),

  success_sign_up: TrackingEvent.actionEnd(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.SUCCESS_SIGN_UP),

  reset_password_continue: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_UPDATE_MEMBERSHIP,
    TrackingConstant.RESET_PASSWORD_CONTINUE
  ),

  sign_out: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SIGN_OUT, TrackingConstant.SIGN_OUT),

  sign_in: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_IN, TrackingConstant.ACTION_SIGN_IN),

  sign_up: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.ACTION_SIGN_UP),

  back_to_top: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RESET_NAVIGATION, TrackingConstant.JUMP_TO_TOP),
  ...AuthenticatedModalMap,
};
