import greatPriceLogo from './deal-type/images/great-price-logo.svg';
import fairPriceLogo from './deal-type/images/fair-price-logo.svg';
import goodPriceLogo from './deal-type/images/good-price-logo.svg';
import highPriceLogo from './deal-type/images/high-price-logo.svg';

export const GREAT_DEAL = 'Great';
export const FAIR_DEAL = 'Fair';
export const GOOD_DEAL = 'Good';
export const HIGH_DEAL = 'High';
export const ABOVE_MARKET = 'Above_market';
export const DEAL_TYPE_ALLOWED_VALUES = [GREAT_DEAL, GOOD_DEAL, FAIR_DEAL, ABOVE_MARKET];
export const DEFAULT_PRICE = 'Not Priced';

export const DEALS_COLORS = {
  [GREAT_DEAL]: 'text-success',
  [FAIR_DEAL]: 'text-warning',
  [GOOD_DEAL]: 'text-primary-lighter',
  [HIGH_DEAL]: 'text-warning-lighter',
};

export const DEALS_BG_COLORS = {
  [GREAT_DEAL]: 'bg-success-gauge',
  [FAIR_DEAL]: 'bg-warning-gauge',
  [GOOD_DEAL]: 'bg-primary-lighter-gauge',
};

export const DEALS_BG_COLORS_STYLE = {
  [GREAT_DEAL]: '#007ee5',
  [FAIR_DEAL]: '#00a390',
  [GOOD_DEAL]: '#0098c2',
  [HIGH_DEAL]: '#eb6b00',
};

export const DEALS_LARGE_TEXT_COLORS_STYLE = {
  [GREAT_DEAL]: '#1358bf', // $blue-30
  [GOOD_DEAL]: '#008ea1', // $turquoise-20
  [FAIR_DEAL]: '#1a854a', // $green-50
  [HIGH_DEAL]: '#eb6817', // $orange-40
};

export const DEALS_SMALL_TEXT_COLORS_STYLE = {
  [GREAT_DEAL]: '#1358bf', // $blue-30
  [GOOD_DEAL]: '#007c8c', // $turquoise-10
  [FAIR_DEAL]: '#1a854a', // $green-50
  [HIGH_DEAL]: '#d62b04', // $orange-10
};

export const DEALS_IMG_ALT_TEXT = {
  [GREAT_DEAL]: 'Great price',
  [FAIR_DEAL]: 'Fair price',
  [GOOD_DEAL]: 'Good price',
  [HIGH_DEAL]: 'High price',
};

export const DEAL_TYPES = [GREAT_DEAL, GOOD_DEAL, FAIR_DEAL];

export const DEAL_COVERAGE = {
  new: {
    [GREAT_DEAL]: '26%',
    [FAIR_DEAL]: '25%',
    [GOOD_DEAL]: '18%',
  },
  used: {
    [GREAT_DEAL]: '15%',
    [GOOD_DEAL]: '30%',
    [HIGH_DEAL]: '25%',
  },
};

export const DEALS_TYPE_PRICE_LOGO = {
  [GREAT_DEAL]: greatPriceLogo,
  [FAIR_DEAL]: fairPriceLogo,
  [GOOD_DEAL]: goodPriceLogo,
  [HIGH_DEAL]: highPriceLogo,
};

export const TEXT_COLOR = {
  [GREAT_DEAL]: 'text-great',
  [GOOD_DEAL]: 'text-good',
  [FAIR_DEAL]: 'text-fair',
  [HIGH_DEAL]: 'text-high',
};

export const DEAL_TYPE_ICON = {
  [GREAT_DEAL]: 'icon-icon-great-price',
  [GOOD_DEAL]: 'icon-icon-good-price',
  [FAIR_DEAL]: 'icon-icon-fair-price',
  [HIGH_DEAL]: 'icon-icon-high-price',
};
